import React from "react";
import Logo from "../../assets/appImages/logo.svg";
import { UseForgetPasswordForm } from "./requestForm/UseForgetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";
import { MdMail } from "react-icons/md";

const ForgetPassword = () => {
  const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm();
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4 w-100">
              <div className="content-logo-auth pb-2 pt-5">
                <img src={Logo} alt="logo" className="logo-auth-page" />
              </div>
              <div className="text-center pt-4 pb-4">
                {/* <h2 className="title-auth-form-text pt-5">
                  Mot de passe oublié?
                </h2> */}
                <div className="content-text-create-account">
                  <span className="text-description-forget-password px-3">
                    Veuillez entrer votre email
                    Nous vous enverrons un lien pour modifier le mot de passe
                  </span>
                </div>
              </div>
              <div className="mt-5">
                <form onSubmit={onSubmit}>
                  <div className="row row-add-student">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group-rounded">
                        <span className="icon-input">
                          <MdMail />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Email"
                          aria-label="Email"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center pt-5">
                        <button
                          // to="/admin/dashboard"
                          className="btn auth-submit-btn trans-0-2"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Validation...</span>
                            </>
                          ) : (
                            "Valider"
                          )}
                        </button>
                    </div>
                </div>
              </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
