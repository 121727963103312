import React from "react";
import { HiUser } from "react-icons/hi";
import { ImLocation2 } from "react-icons/im";
import { FaFolderMinus, FaShoppingCart } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import "./MonCompte.css";
import { NavLink, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/hook";
import { onlogout } from "../../redux/slice/User.slice";


const MonCompteSidebar = () => {
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(onlogout());
    setTimeout(() => {
      window.location.pathname = "/";
    }, 500);
  };

  const location = useLocation();
  const verifLocation = (path: string) => {
    return location?.pathname?.startsWith(path)
  } 

  return (
    <div className="sidebar-tabs-container user-parcours">
      <ul className="tabs-container">
        <li className="list-sidebar-compte">
          <NavLink 
            to="/mon-compte/profil" 
            className={`
              btn btn-compte-sidebar 
              ${verifLocation("/mon-compte/profil") && 'active'}
            `}
          >
            <HiUser />
            <span>Mon profil</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink
            to="/mon-compte/commandes"
            className={`
              btn btn-compte-sidebar 
              ${verifLocation("/mon-compte/commandes") && 'active'}
            `}
          >
            <FaFolderMinus />
            <span>Mes commandes</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink 
            to="/mon-compte/panier" 
            className={`s
              btn btn-compte-sidebar 
              ${verifLocation("/mon-compte/panier") && 'active'}
            `}
          >
            <FaShoppingCart />
            <span>Mon panier</span>
          </NavLink>
        </li>
        <li className="list-sidebar-compte">
          <NavLink 
            to="/mon-compte/favoris" 
            className={`
              btn btn-compte-sidebar 
              ${verifLocation("/mon-compte/favoris") && 'active'}
            `}
          >
            <AiFillHeart />
            <span>Mes favoris</span>
          </NavLink>
        </li>
      </ul>
      <button className="btn btn-logout-compte" onClick={() => onLogout()}>
        Déconnexion
      </button>
    </div>
  );
};

export default MonCompteSidebar;
