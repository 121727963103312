/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from "react";
import "./HomePage.css";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import BannerHomepage from "./BannerHomepage";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineSend,
} from "react-icons/ai";
import Fade from "react-reveal/Fade";
import StarRatingComponent from "react-star-rating-component";
import { NavLink , Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import Skeleton from "react-loading-skeleton";
import Icon1 from "../../assets/icons/icon1.svg";
import Icon2 from "../../assets/icons/icon2.svg";
import Icon3 from "../../assets/icons/icon3.svg";
import Icon4 from "../../assets/icons/icon4.svg";
import Icon5 from "../../assets/icons/icon5.svg";
import Product1 from "../../assets/appImages/product1.png"
import Product2 from "../../assets/appImages/product2.png"
import Product3 from "../../assets/appImages/product3.png"
import Product4 from "../../assets/appImages/product4.png"
import Product5 from "../../assets/appImages/product5.png"
import Product6 from "../../assets/appImages/product6.png"
import Product7 from "../../assets/appImages/product7.png"
import Product8 from "../../assets/appImages/product8.png"
import Product9 from "../../assets/appImages/product9.png"
import Product10 from "../../assets/appImages/product10.png"
import Product11 from "../../assets/appImages/product11.png"
import Product12 from "../../assets/appImages/product12.png"
import Empty from "../../assets/appImages/category.png"
import AliceCarousel from 'react-alice-carousel';
import Testimonial from "./Testimonial";
import { FiShoppingCart } from "react-icons/fi";
import { BsCartCheck } from "react-icons/bs";
import { getImage } from "../../utils/Utils";
import { useGetProductListQuery} from "../../utils/api/product/product.api";
import { useGetAllCategoryListQuery} from "../../utils/api/category/category.api";
import {VendeurApikey} from "../../utils/http";
import { usePagination, useSearch } from "../../utils/hooks";


export const responsiveI = {
    0: { items: 1 },
    992: { items: 2 },
    1024: { items: 4 },
};
const HomePage = () => {

  const { limit, page, onChange } = usePagination(10);
  const { data , refetch } = useGetProductListQuery({
    page: page,
    limit: limit
  });
  const { data:categories } = useGetAllCategoryListQuery();


  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
          <BannerHomepage />
          <div className="container-easy-market mb-5">
          <div className="py-5">
            <div className="container-list-category-product">
              {categories?.results?.map((item) =>
              <Fade bottom>
              <Link state={{ catgoryId: item?.id }} className="link-footer link-footer-deco trans-0-2 d-block" to="/produits">
                <div className="category-product-accueil-item" key={item.id}>
                  <p className="title-category-product-item">{item?.nom}</p>
                  <div className="content-icon-category-product-accueil">
                    {item?.images ? 
                      <img className="catgoryImg" src={getImage(item?.images)} alt={item?.nom} />
                      :
                      <img src={Icon2} alt={item?.nom} />
                    }
                  </div>
                </div>
              </Link>
              </Fade>
              )}
            </div>
          </div>
          <div className="container-home-page mb-3 my-5">
            <div className="content-title-home-page pb-5">
              <div className="title-home-page-container">
                <Zoom bottom cascade>
                  <h3 className="title-home-page-theme text-center">
                    Nouveaux produits  
                  </h3>
                </Zoom>
              </div>
            </div>
            <div className="">
              <AliceCarousel 
                  mouseTracking
                  responsive={responsiveI}
                  autoPlayStrategy="default"
                  controlsStrategy="alternate"
                  autoPlay={true}
                  infinite={true}
                  keyboardNavigation={true}
                  autoPlayInterval={2000}
              >
                  {!! data && data?.results?.slice(Math.max(data?.results?.length - 10, 0))?.map((produit, key) => (
                    <div
                      className="col-product-item"
                      key={key}
                    >
                        <ProductItem item={produit} key={key} />
                    </div>
                  ))}
                </AliceCarousel>
            </div>
          </div>
          <div className="container-home-page my-5">
            <div className="content-title-home-page pb-5">
              <div className="title-home-page-container">
                <Zoom bottom cascade>
                  <h3 className="title-home-page-theme text-center">
                    Nos Produits 
                  </h3>
                </Zoom>
              </div>
            </div>
            <div className="container-list-product-home">
              <div className="row">
                {!! data && data?.results?.slice(Math.max(data?.results?.length - 12, 0))?.map((produit, key) => (
                <div className="col-md-3 col-content-product-home d-flex mb-4 pb-4">
                  <ProductItem item={produit} key={key} />
                </div>
                 ))}
              </div>
            </div>
            <div className="content-btn-title-home-page flex-r">
                <Bounce right>
                  <NavLink to="/produits" className="btn btn-see-more-product">
                    Voir plus
                  </NavLink>
                </Bounce>
              </div>
          </div>
          
        </div>
        <div className="py-5">
          <div className="content-title-home-page pb-5">
              <div className="title-home-page-container">
                <Zoom bottom cascade>
                  <h3 className="title-home-page-theme text-center">
                    Ce que dises nos clients
                  </h3>
                </Zoom>
              </div>
            </div>
        <Testimonial />
        </div>
        {/* <div className="content-news-letter mt-5">
          <div className="container-easy-market">
            <div className="row align-items-center">
              <div className="col-md-3 col-left-news-letter mb-3">
                <div className="content-col-left-news-letter">
                  <p className="title-news-letter mb-1">NEWSLETTER</p>
                  <p className="sub-title-news-letter mb-0">Stay Upto Date</p>
                </div>
              </div>
              <div className="col-md-9 col-right-news-letter mb-3">
                <div className="content-col-right-news-letter position-relative">
                  <input type="email" placeholder="Votre email" className="form-control form-control-news-letter" />
                  <button className="btn btn-send-news-letter">
                    <AiOutlineSend />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <FrontFooter />
    </div>
  );
};

export default HomePage;
const Datas = [
  {
    id: 1,
    title: "Hommes",
    imgIcon: Icon1
  },
  {
    id: 2,
    title: "Femmes",
    imgIcon: Icon2
  },
  {
    id: 3,
    title: "Enfants",
    imgIcon: Icon3
  },
  {
    id: 4,
    title: "Équipements ",
    imgIcon: Icon4
  },
  {
    id: 5,
    title: "Parking",
    imgIcon: Icon5
  },
  {
    id: 6,
    title: "Parking",
    imgIcon: Icon5
  },
  {
    id: 7,
    title: "Parking",
    imgIcon: Icon5
  },
]
export const ProductData = [
  {
    id: 1,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product1,
    simm: false,
    rupture: false
  },
  {
    id: 2,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product2,
    simm: false,
    rupture: false
  },
  {
    id: 3,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product3,
    simm: false,
    rupture: false
  },
  {
    id: 4,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product4,
    simm: false,
    rupture: false
  },{
    id: 5,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product5,
    simm: false,
    rupture: false
  },
  {
    id: 6,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product6,
    simm: false,
    rupture: false
  },
  {
    id: 7,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product7,
    simm: false,
    rupture: false
  },
  {
    id: 8,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: true,
    img: Product8,
    simm: false,
    rupture: false
  },
  {
    id: 9,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: false,
    img: Product9,
    simm: false,
    rupture: false
  },
  {
    id: 10,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: true,
    new: false,
    img: Product10,
    simm: false,
    rupture: false
  },
  {
    id: 11,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: false,
    img: Product11,
    simm: true,
    rupture: false
  },
  {
    id: 12,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: true,
    new: false,
    img: Product12,
    simm: false,
    rupture: true
  },{
    id: 13,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: false,
    img: Product5,
    simm: true,
    rupture: true
  },
  {
    id: 14,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: false,
    img: Product6,
    simm: true,
    rupture: false
  },
  {
    id: 15,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: false,
    img: Product7,
    simm: true,
    rupture: false
  },
  {
    id: 16,
    name: "Cotton shirt Regular Fit",
    price: "150 000 fca",
    favorite: false,
    new: false,
    img: Product8,
    simm: false,
    rupture: false
  },
]
export const ProductItem = ({ item }: { item: any }) => {
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [isAddedToCart, setIsAddedToCart] = React.useState(false);
  React.useEffect(() => {
    if (item?.favorite === true) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [item]);

  return (
    <Fade bottom cascade>
      <div className="product-item-container">
        
        <NavLink
          to={`/produit/${item?.slug}`}
          state={item}
          className="no-link w-100"
        >{item?.new === true ? <span className="statut-product-item">Nouveau</span> :
          item?.rupture === true ? <span className="statut-product-item-rupture">Rupture de stock</span> : ""}
          
          <div className="content-img-product-item">
            <img
              src={item?.variations?.length ? getImage(item?.variations[0]?.images[0]?.image) : Empty}
              alt={item?.articleName}
              className="img-product-item"
            />
            
          </div>
           <div className="container-body-product-item">
            <p className="name-product-item my-2">{item?.nom} </p>
              <div className="content-price-product">
                <p className="price-product-item d-flex">
                  {item?.prix} FCFA
                </p>
              </div>
               <div className="container-cta-chat-produit mt-3">
                {item.rupture === false ? (
                   <div className="content-btn-achat-produit w-100">
                        <NavLink to={`/produit/${item?.slug}`} className="btn btn-theme w-100">Acheter</NavLink>
                    </div>
                ) : (
                    <div className="content-btn-achat-produit w-100">
                        <button className="btn btn-theme w-100 disablead">Acheter</button>
                    </div>
                )}
              {/* <div className="content-btn-add-to-panier">
                {item.rupture === false ? (
                <button 
                  className={`
                    btn btn-theme-outline ${isAddedToCart ? 'added_to_card' : ''}
                  `}
                  onClick={() => setIsAddedToCart(!isAddedToCart)}
                >
                  {isAddedToCart ? <BsCartCheck /> : <FiShoppingCart />}
                </button>
                 ) : (
                  <button 
                  className="btn btn-theme-outline disabled"
                >
                  {isAddedToCart ? <BsCartCheck /> : <FiShoppingCart />}
                </button>
                 )}
              </div> */}
            </div>
           </div>
          </NavLink>
          <button className="btn icon-fav-product">
          {item?.favorite === true ? (
            <AiFillHeart style={{ color: "#E58924" }} />
          ) : (
            <AiOutlineHeart />
          )}
        </button>
       
      </div>
    </Fade>
  );
};



export const ProductItemSkeleton = () => {
  return (
    <>
      {Array(12)
        .fill(12)
        .map((item, i) => (
          <div className="col-product-item" key={i}>
            <div
              className="product-item-container"
              style={{ border: "1px solid #ebebeb", textAlign: "center" }}
            >
              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={`50%`}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={`60%`}
                  height={20}
                />
                <Skeleton
                  className="name-prduct fs-15"
                  width={`70%`}
                  height={15}
                />
                <div className="content-rate-product">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={0}
                    starColor="#000000"
                    emptyStarColor="#D4D6D5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
