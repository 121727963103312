import React from "react";
import { NavLink } from "react-router-dom";
import { useGetSearchProductListQuery } from "../../../utils/api/product/product.api";
import { formatCurrency, getImage } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";

function SearchForm() {
  const [query, setQuery] = React.useState("");
  const { data = { results: [] } } = useGetSearchProductListQuery({
    search: query,
  });

  // React.useEffect(() => {
  //   console.log("query data", data);
  // }, [data]);
  return (
    <div className="input-group-search relative-search-form">
      <div>
          <span className="input-group-btn">
            <i className="fa fa-search"></i>
        </span>
        <input
          type="text"
          className="form-control search-field"
          onChange={(e) => setQuery(e?.target?.value)}
          placeholder="Rechercher tout sur Easy market"
        />
        {query && query?.length >= 3 && (
          <div className="list-container">
            <ul className="search-result-container">
              {data?.results?.length ? (
                data?.results?.map((item) => (
                  <li className="search-result-item" key={item?.id}>
                    <NavLink
                      to={{
                        pathname: `/produit/${item?.slug}`,
                      }}
                      state={item}
                      //   onClick={() => setSearching("")}
                      key={`search${item?.id}`}
                      className="no-link"
                    >
                      <div className="row align-items-center">
                        <div className="col-sm-3 m-b-20">
                          <img
                            className="img-thumbs-commande-item"
                            src={getImage('/')}
                            alt={item?.nom}
                            style={{
                              width: "75px",
                              height: "75px",
                              //   objectFit: "contain",
                            }}
                          />
                        </div>
                        <div className="pl-lg-0 col-sm-9 m-b-20">
                          <p className="name-prduct fs-15">{item?.nom}</p>
                          <div className="content-price-product-solde justify-content-start align-items-baseline">
                            <p className="price-product-item ">
                              {item?.prix}{" "}
                              <span style={{ fontWeight: 300 }}>FCFA</span>{" "}
                            </p>
                            {!!item?.id && (
                              <p className="price-solde-product-item mb-0">
                                {formatCurrency(item?.prix)}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* {r.name} */}
                    </NavLink>
                  </li>
                ))
              ) : (
                <li className="search-result-item">
                  <AlertInfo message="Aucun résultat trouvé!" />
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      
    </div>
  );
}

export default SearchForm;
