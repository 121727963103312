/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
} from "../../../../../utils/Utils";
import { OrderFormData , Order } from "../../../../../utils/api/order/order.type";
import { useAddOrEditOrderMutation , useDeleteOrderMutation } from "../../../../../utils/api/order/order.api";
import { Color } from "../../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../../utils/helpers";


function UseAddOrUpdateOrderForm(order?: Order) {

  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Le titre"),
    description: yup.string().required().label("Le description"),
    // montant: yup.string().required().label("Le montant"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<OrderFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateOrder, { isLoading, isSuccess, error, isError }] =
  useAddOrEditOrderMutation();

  const handleChangeIcone = (e) => { 
    let files = e.target.files[0];
    //setValue("icone" , files)
  }

  const handleChangeBanniere = (e) => { 
    let files = e.target.files[0];
   // setValue("banniere" , files)
  }

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Order ${order ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 6000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/orders')
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("The email has already been taken.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {

    if (order?.slug) {
      let fields: (keyof OrderFormData)[] = [

        'slug',	
        'statut',
        'montant_total' ,	
        'code_commande' ,	
        'mode_de_paiement',	
        'is_paid'	,
        'devise',	
        'confirmation_client'	,
        'transaction_intech_code',	
        'user' ,	
        'vendeur' ,	
        'produits' , 
      ];
      for (let field of fields) {
        register(field);
      //  setValue(field, order[field])
        
      }

    }
  }, [order]);

  const onSubmit = async (data: OrderFormData) => {
      let fd = new FormData();
      
      // fd.append("modeLivraison", data?.modeLivraison);
      // fd.append("paymentMethod", data?.paymentMethod);
      // fd.append("store", data?.store);
     // fd.append("adresse", data?.adresse);
      // fd.append("orderItems", data?.orderItems);
      // fd.append("user", data?._id);
      // fd.append("preparer", data?.preparer);
      // fd.append("userObj", data?._id);
     // fd.append("adresseObj", data?.adresseObj);
      //fd.append("totalAmount", data?.totalAmount);
      //fd.append("subTotal", data?.subTotal);
     // fd.append("tva", data?.tva);
      //fd.append("livraison", data?.livraison);
    //  fd.append("numOrder", data?.numOrder);
      
    

      await addOrUpdateOrder({ slug: order?.slug, data: fd });
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeIcone,
    handleChangeBanniere
  };
}

export function DeleteOrder({ item }: { item: Order }) {
  console.log('item',item)
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteOrderMutation();
  const onDelete = useDelete<Order>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette commande",
    successMessage: "Commande supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export default UseAddOrUpdateOrderForm;
