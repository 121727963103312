import React, { useState,useEffect } from 'react'
import { AlertInfo } from '../../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from '../../../../common/TableSkeleton';
import { FaTrash } from "react-icons/fa";
import { AiFillEye } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { formatDateHour } from "../../../../../utils/Utils";
import { DeleteOrder } from "../requestForm/UseAddOrUpdateOrderForm"



function ListeCommandeTable({commandes, isLoading}) {

  const [pages, setPages] = useState(ListeCommandeTableData)


    const actionFormatter = (cell: any, row: any) => {
      return (
        <>
          <div className="table-actions-btn-container-commandes d-flex justify-content-center align-items-center gap-1">
            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/mon-compte/commandes/${row.slug}`}
                className="btn btn-action-modal-icon with-tooltip"
                data-tooltip-content="Voir"
                style={{ color: "#158A91" }}
              >
                <AiFillEye />
              </NavLink>
            </div>
            <div className="container-btn-action-icon-modal">
                {/* <button
                    className="btn with-tooltip btn-action-icon-delete"
                    data-tooltip-content="Supprimer"
                    style={{ color: "#E53E3E" }}
                >
                    <FaTrash />
                </button> */}
                <DeleteOrder item={row} />
            </div>
          </div>
        </>
      );
    };

    const statutFormatter = (cell: any, row: any) => {
      return(
        <div className="d-flex align-items-center justify-content-start gap-2 command-and-payment-status">
          {cell === "EN_COURS_DE_TRAITEMENT" &&
            <div className="command-status">En cours de traitement </div>
          }
          {cell === "EN_COURS_DE_LIVRAISON" &&
            <div className="command-status inprogress">En cours de Livraison</div>
          }
          {cell === "LIVRE" &&
            <div className="command-status delivered">Livrée</div>
          }
          {cell === "ANNULE" &&
            <div className="payment-status not-paid">Annulée</div>
          }
          {/* {cell === "Livrée" &&
            <div className="payment-status paid">Payées</div>
          } */}
        </div>
      )
    }


    const dateFormatter = (cell: any, row: any) => {
      return(
        <div className="d-flex align-items-center justify-content-start gap-2">
          {formatDateHour(cell)}
        </div>
      )
    }

    const columns = [
      {
        dataField: "code_commande",
        text: "N Commande",
      },
      {
        dataField: "date_created",
        text: "Date",
        formatter: dateFormatter
      },
      {
        dataField: "montant_total",
        text: "Montant total",
      },
      {
        dataField: "statut",
        text: "Statut",
        formatter: statutFormatter
      },
      {
        dataField: "id",
        text: "Action",
        formatter: actionFormatter,
      },
    ];

    return (
      <>
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <BootstrapTable
              keyField="id"
              data={commandes || []}
              columns={columns}
              rowClasses="custom-row-class"
              condensed
              hover
              wrapperClasses="table-responsive commande-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
          />
        )}
      </>
    );
}

export default ListeCommandeTable

const ListeCommandeTableData = [
    {
        id: 1,
        slug: "command-1",
        numero_commande: "35564748",
        date: "12/10/2022",
        amount: "1 000 f",
        statut: "En cours de traitement",
    },
    {
        id: 2,
        slug: "command-2",
        numero_commande: "35564748",
        date: "12/10/2022",
        amount: "1 000 f",
        statut: "En cours de Livraison",
    },
    {
        id: 3,
        slug: "command-3",
        numero_commande: "35564748",
        date: "12/10/2022",
        amount: "1 000 f",
        statut: "Livrée",
    },
    {
        id: 4,
        slug: "command-4",
        numero_commande: "35564748",
        date: "12/10/2022",
        amount: "1 000 f",
        statut: "En cours de traitement",
    },
]

