import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./PolitiqueConfidentialite.css";


function PolitiqueConfidentialite() { 
    return(
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <h1 className="custom-page-title">
                    Politique de confidentialité
                </h1>
                <div className="container">
                    <div className="custom-politique-page-content pt-5">
                        <h3 className="custom-sm-title pb-1 pb-4">
                            Politique de Confidentialité d'Easymarket
                        </h3>
                        <p className="update-date-content">
                            Dernière mise à jour : 22 Septembre 2023
                        </p>
                        <div className="content-all-politique pt-4 pb-3">
                            <div className="content-politique-item">
                                <h3 className="custom-sm-title pb-4">
                                    1. Introduction
                                </h3>
                                <div className="custom-sm-value">
                                    Easymarket (ci-après "nous", "notre", "nos" ou "la société") est une solution en marque blanche qui permet aux vendeurs de créer et de gérer rapidement leur site et leur application e-commerce. Nous accordons une grande importance à la protection de la vie privée de nos utilisateurs et nous nous engageons à respecter les lois et réglementations en vigueur en matière de données personnelles.
                                    Cette politique de confidentialité explique comment nous collectons, utilisons, partageons et protégeons les informations personnelles que vous nous fournissez lorsque vous utilisez notre solution en marque blanche. En utilisant Easymarket, vous consentez à la collecte et à l'utilisation de vos informations personnelles conformément à cette politique.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    2. Informations Collectées
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-4">
                                            2.1 Informations Collectées
                                        </h3>
                                        <div className="custom-sm-value">
                                            Lorsque vous utilisez Easymarket, nous pouvons collecter les types de données personnelles suivants :
                                            <ul className="custom-instruction-list">
                                                <li className="listitem">
                                                    Informations d'identification : nom, prénom, adresse e-mail, numéro de téléphone, adresse postale.
                                                </li>
                                                <li className="listitem">
                                                    Informations de paiement : informations de carte de crédit ou de compte bancaire (le cas échéant).
                                                </li>
                                                <li className="listitem">
                                                    Informations de connexion : nom d'utilisateur, mot de passe et autres informations d'authentification.
                                                </li>
                                                <li className="listitem">
                                                    Informations de commande : détails de vos commandes, historique d'achat et informations de livraison.
                                                </li>
                                                <li className="listitem">
                                                    Informations de navigation : adresse IP, données de navigation, type de navigateur, préférences de langue.
                                                </li>
                                                <li className="listitem">
                                                Informations sur l'appareil : type d'appareil, système d'exploitation, identifiant unique de l'appareil.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-2">
                                        <h3 className="custom-sm-title pb-4">
                                            2.2 Informations Collectées
                                        </h3>
                                        <div className="custom-sm-value">
                                            Nous pouvons également collecter des données non personnelles, telles que des données agrégées et anonymes sur l'utilisation de notre solution en marque blanche à des fins d'analyse et d'amélioration de nos services. <br />
                                            Nous utilisons vos données personnelles aux fins suivantes : <br />
                                            <ul className="custom-instruction-list">
                                                <li className="listitem">
                                                    Fourniture de nos services : pour créer et gérer votre site et votre application e-commerce en marque blanche, traiter vos commandes et vous offrir une expérience utilisateur optimale.
                                                </li>
                                                <li className="listitem">
                                                    Communication : pour vous informer sur les mises à jour de nos services, les promotions et les offres spéciales.
                                                </li>
                                                <li className="listitem">
                                                    Assistance client : pour répondre à vos demandes d'assistance et de support.
                                                </li>
                                                <li className="listitem">
                                                    Sécurité : pour protéger nos services, détecter et prévenir les activités frauduleuses ou malveillantes.
                                                </li>
                                                <li className="listitem">
                                                    Analyse : pour améliorer nos services, comprendre les tendances d'utilisation et effectuer des analyses statistiques.
                                                </li>
                                            </ul>
                                            
                                            
                                            
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    3. Partage des Données Personnelles
                                </h3>
                                <div className="custom-sm-value">
                                    Nous ne vendons pas, ne louons pas et ne partageons pas vos données personnelles avec des tiers à des fins de marketing direct. <br /> 
                                    Cependant, nous pouvons partager vos données personnelles dans les cas suivants : <br />
                                    <ul className="custom-instruction-list">
                                        <li className="listitem">
                                            Avec nos partenaires commerciaux et prestataires de services pour la fourniture de nos services.
                                        </li>
                                        <li className="listitem">
                                            Lorsque cela est requis par la loi ou dans le cadre d'une procédure judiciaire.
                                        </li>
                                        <li className="listitem">
                                            Pour protéger nos droits, notre sécurité et nos biens, ainsi que ceux de nos utilisateurs.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    4. Sécurité des Données
                                </h3>
                                <div className="custom-sm-value">
                                    Nous prenons des mesures de sécurité techniques, administratives et physiques appropriées pour protéger vos données personnelles 
                                    contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    5. Vos Choix et Droits
                                </h3>
                                <div className="custom-sm-value">
                                    Vous avez le droit de : <br />
                                    <ul className="custom-instruction-list">
                                        <li className="listitem">
                                            Accéder à vos données personnelles et les corriger si elles sont inexactes.
                                        </li>
                                        <li className="listitem">
                                            Demander la suppression de vos données personnelles, sauf si nous avons une obligation légale de les conserver.
                                        </li>
                                        <li className="listitem">
                                            Vous opposer au traitement de vos données personnelles à des fins de marketing direct.
                                        </li>
                                        <li className="listitem">
                                            Exercer d'autres droits prévus par la législation applicable en matière de protection des données.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    6. Modification de cette Politique de Confidentialité
                                </h3>
                                <div className="custom-sm-value">
                                    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront affichées sur notre site Web et vous serez informé des changements importants par d'autres moyens appropriés.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    7. Contact
                                </h3>
                                <div className="custom-sm-value">
                                    Si vous avez des questions concernant cette politique de confidentialité ou nos pratiques en matière de protection des données, vous pouvez nous contacter à l'adresse suivante :
                                    Hlm Grand Yoff Villa N° 263 11500, Dakar ( Sénégal)
                                    +221 77 171 48 18
                                    contact@easymarket.sn
                                </div>
                            </div>
                        </div>
                        <div className="custom-sm-value pt-5 pb-3">
                            Nous vous remercions de votre confiance en Easymarket pour la création de votre site et de votre application e-commerce. Nous nous engageons à protéger vos données personnelles et à vous offrir une expérience de qualité.
                        </div>
                    </div>
                </div>
            </div>
            <FrontFooter />
        </div>
    )
}

export default PolitiqueConfidentialite