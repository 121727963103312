import React, { ChangeEvent,useEffect } from "react";
import InputRange from "react-input-range";
import { useGetAllCategoryListQuery} from "../../../utils/api/category/category.api";
import RadioButton from "../../common/RadioButton/inputs/RadioButton";
import { usePagination, useSearch } from "../../../utils/hooks";


function FilterItems({value, setValue, searchCategorys, setSearchCategorys, query, setQuery, catgoryId }) {
  const { limit, page, onChange } = usePagination(10);
  const { data , refetch } = useGetAllCategoryListQuery();

  const handleSelectCategory = (e) => {
    
    if(e?.target?.checked)
    {
      setSearchCategorys([...searchCategorys,e?.target?.value])
    }else
    {
      
      setSearchCategorys(searchCategorys.filter((x) => x !== e?.target?.value))
    }

  };

  useEffect(() => {
    console.log('searchParams',value)
}, [value]);
  


  return (
    <div className="col-lg-3 col-md-5 col-left-achat-page">
      <div
        className="content-col-left-achat-page"
        style={{ minHeight: "70rem" }}
      >
        <div className="content-others-value my-4 pb-3">
          <p className="content-title-section-left">Catégorie</p>
          <div
            className="category-filter-list-check"
          >
            {data?.results?.map((item, i) => (
            <label className="material-checkbox mb-3" key={i}>
              <input defaultChecked={catgoryId==item?.id} type="checkbox" value={item?.id} name="categorie" onChange={(e)=>handleSelectCategory(e)} />
              <span className="checkmark"></span>
                {item?.nom}
            </label>
             ))}
          </div>
        </div>
        <p className="content-title-section-left">
          Prix <span style={{ fontWeight: 300, fontSize: 14 }}>(F CFA)</span>
        </p>
        <div className="pb-4 py-4">
          {/* <div className="filter-prix">
            <InputRange
              maxValue={100000}
              minValue={0}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </div> 
          <div className="d-flex gap-2 mt-5 container-price align-items-center">
            <div className="price-container">{value?.min || 0}</div>-
            <div className="price-container">{value?.max || 0}</div>
          </div> */}
          <label className="material-checkbox mb-3" key='10'>
            <input type="radio" value={10} name="prix" onChange={()=>setValue({min:0,max:10000})} />
            <span>Jusqu'a 10 000</span>
          </label>
          <label className="material-checkbox mb-3" key='25'>
            <input type="radio" value={25} name="prix" onChange={()=>setValue({min:10000,max:25000})} />
              <span>De 10 000 à 25 000</span>
          </label>
          <label className="material-checkbox mb-3" key='50'>
            <input type="radio" value={50} name="prix" onChange={()=>setValue({min:25000,max:50000})} />
            <span>De 25 000 à 50 000</span>
          </label>
          <label className="material-checkbox mb-3" key='60'>
            <input className='radio-input' type="radio" value={60} name="prix" onChange={()=>setValue({min:50000,max:900000000})} />
            <span>De 50 000 et plus</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default FilterItems;
