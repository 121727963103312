import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { cleannerError, showModal } from "../../../utils/Utils";
import { AppStorage } from "../../../utils/storage";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";


function UseLoginForm(closeModalConnexion: () => void) {
  const validationSchema = yup.object().shape({
    email: yup.string().required().label("L'email ou le numero de téléphone"),
    password: yup.string().required().label("Le mot de passe"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    reset,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserMutation();


  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {

    const res = await loginUser(data);

    if ("data" in res) {

      Swal.fire({
				icon: "success",
				title: "Connexion reussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				 //AppStorage.setItem("user", res?.data);
        dispatch(onSetUserToken({ user: res?.data, token: res?.data?.token }));
        closeModalConnexion()
        
        if (res?.data?.user_type === "ACHETEUR") {
          navigate("/produits");
        }else{
          navigate("/produits");
        }
			});
          
    

     // reset();
    }

    if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			let message = err?.data?.message
				? err?.data?.message
				: err?.status < 500
				? "Email/Téléphone ou mot de passe incorrect"
				: `Une erreur de statut ${err?.status} est survenue.`;
			setError("email", {
				message: message,
			});
		}
      

  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseLoginForm;
