import React, { useState } from "react";
import { useGetFavoriteListQuery } from "../../../utils/api/favories/favorie.api";
import { useAppSelector } from "../../../redux/hook";
import { AlertInfo } from "../../common/Alert";
import { ProductItem } from "../../HomePage/HomePage";
import Pagination from "../../common/Pagination";
import { ProductItemsSkeleton } from "../../user/ParcoursAchat/ParcoursAchat";

const FavorisPage = () => {
  const [page, setPage] = useState(1);
  const { user } = useAppSelector((s) => s?.user);
  const { data = [], isLoading } = useGetFavoriteListQuery({});
  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="user-parcours-title">Mes favoris</h3>
      </div>
      <div className="content-panier-page mt-5">
        <div className="row row-panier-page">
          {!!isLoading && <ProductItemsSkeleton />}
          {!isLoading &&
            (data?.length ? (
              data?.map((item) => (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 col-favoris-page mb-3"
                  key={item?.id}
                >
                  {/* <div className="card-favoris-product text-center">
                    <NavLink
                      to={`/produit/${item?.product?._id}`}
                      state={item?.product}
                      className="no-link w-100"
                    >
                      <span className="icon-fav-product">
                        <AiFillHeart
                          style={{ color: "#E20613", fontSize: 20 }}
                        />
                      </span>
                      <p className="title-card-favoris-product mb-1">
                        {item?.product?.name}
                      </p>
                      <div className="content-img-commande-item py-3">
                        <img
                          src={getImage(item?.product?.pictures[0]?.image)}
                          alt={item?.product?.name}
                          className="img-commande-item"
                        />
                      </div>
                      <div className="content-rate-product pb-3">
                        <StarRatingComponent
                          name="rate2"
                          starCount={5}
                          value={item?.product?.rateCount}
                          starColor="#DD9812"
                          emptyStarColor="#D4D6D5"
                        />
                      </div>
                      <p className="text-price-commande-item">
                        {item?.product?.price}
                        <span style={{ fontWeight: 400 }}>FCFA</span>
                      </p>
                    </NavLink>

                    <div className="content-btn-add-to-panier-product pt-3">
                      <button className="btn add-panier-theme w-100">
                        <FaShoppingCart />
                        <span className="ps-2">Ajouter au panier</span>
                      </button>
                    </div>
                  </div> */}
                  <ProductItem item={item} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucun produit disponible dans vos favoris!" />
            ))}
        </div>
        {/* <div className="d-flex justify-content-center">
          {data?.nbPage && data?.nbPage > 1 ? (
            <Pagination
              page={page}
              total={data?.nbPage}
              onPageChange={(page) => setPage(page)}
            />
          ) : null}
        </div> */}
      </div>
    </div>
  );
};

export default FavorisPage;
