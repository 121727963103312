import React from "react";
import { FaUser } from "react-icons/fa";
import UseLoginForm from "../requestForm/UseLoginForm";
import ErrorMessage from "../../common/ErrorMessage";
import { RiLockFill } from "react-icons/ri";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";

function LoginTab({closeModalConnexion}) {

    const { 
        register, 
        errors, 
        isLoading, 
        onSubmit 
    } = UseLoginForm(closeModalConnexion);
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <>
            <form id="auth-form" onSubmit={onSubmit}>
                <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <FaUser />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Email ou Téléphone"
                          aria-label="email"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-2">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <RiLockFill />
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control auth-form-control"
                          id="password"
                          placeholder="Mot de passe"
                          aria-label="Password"
                          {...register("password")}
                        />
                        <span
                          className="show-hide-password"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        message={errors?.password && errors?.password?.message}
                      />
                    </div>
                    <div className="remember_me_and_forgot_password py-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="form-check custom_check__content d-flex align-items-center p-0">
                          <input 
                            className="form-check-input m-0 " 
                            type="checkbox" 
                            value="" 
                            id="remember__me" 
                          />
                          <label className="form-check-label" htmlFor="remember__me">
                            Se souvenir de moi
                          </label>
                        </div>
                        <NavLink
                          to={"/mot-de-passe-oublie"}
                          className="forget-password-link"
                        >
                          Mot de passe oublié ?
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center pt-5">
                        <button
                          className="btn auth-submit-btn trans-0-2"
                          role="button"
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Connexion...</span>
                            </>
                          ) : (
                            "Connexion"
                          )}
                        </button>
                    </div>
                  </div>
            </form>
        </>
    )
}

export default LoginTab;