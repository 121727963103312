import React from "react";
import { NavLink } from "react-router-dom";
import Banner from "../../assets/appImages/img-banner.png"
import Deco from "../../assets/appImages/deco.png"

function BannerHomepage() {


  return (
    <section className="section-banner-homepage">
      <div className="row align-items-center">
        <div className="col-md-7 col-left-banner-accueil mb-3">
          <div className="content-col-left-banner-accueil">
            <h2 className="title-home-page">Votre site e-commerce </h2>
            <h2 className="title-home-page"><span style={{ fontSize: 80 }}>personnalisable</span></h2>
            <div className="content-text-home-page-banner mt-2 mb-5">
              <p className="text-home-page-banner">Vendez facilement vos meilleurs produits grâce à Easy Market</p>
            </div>
            <div className="container-btn-banner-accueil">
              <NavLink className="btn btn-title-home-page" to="/produits">Voir nos produits</NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-right-banner-accueil mb-3">
          <div className="content-col-right-banner-accueil">
            <div className="container-img-col-right-banner-accueil">
              <img src={Banner} alt="Banniere" className="img-col-right-banner-accueil" />
            </div>
            <div className="container-img-deco-banner">
              <img src={Deco} alt="Deco" className="img-deco-banner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerHomepage;
