type ENV = 'dev' | 'prod' | 'demo'

export const currentEnv: ENV = "prod";

const env: ENV = currentEnv;

export const Env = env

const API_DEV_URL = "https://easy-market-api.withvolkeno.com";
const API_DEMO_URL = "https://easy-market-api.withvolkeno.com";
const API_PROD_URL = "https://api.easymarket.sn";

export const APP_URL_DEV = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_PROD = "https://web.easymarket.sn/";

function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}
export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";

export const VendeurApikey = "fa8642a1fccd7cd6bba9f1b73459c32885062b57f0e90784d6114e56b978c2f7";

export const IntechApikey = "554ACA96-F050-442D-8E29-BEE77FAE821A";