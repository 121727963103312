import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import UseLoginForm from "../../../auth/requestForm/UseLoginForm";
import ErrorMessage from "../../../common/ErrorMessage";
import CloseModalBtn from "../../CloseModalBtn";
import ForgetPasswordModal from "./ForgetPasswordModal";
import PhoneModal from "../../../auth/PhoneModal";
import Logo from "../../../../assets/appImages/logo.svg";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import LoginTab from "../../../auth/Tabs/LoginTab";
import RegisterTab from "../../../auth/Tabs/RegisterTab";

const steps = [
  {id: "login-tab", Component: LoginTab },
  {id: "register-tab", Component: RegisterTab }
]
const tabs = [
  "Connexion",
  "Inscription"
]



const ConnexionModal = ({ modalConnexion, setModalConnexion }) => {
  function closeModalConnexion() {
    setModalConnexion(false);
  }

  const {
    step,
    navigation: {go},
    index
  }: useStepType = useStep({initialStep: 0, steps});
  const {Component} = step;

  const [showPassword, setShowPassword] = useState(false);

  const { register, errors, isLoading, onSubmit } =
    UseLoginForm(closeModalConnexion);
  return (
    <Modal
      isOpen={modalConnexion}
      onRequestClose={closeModalConnexion}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="auth-row">
        <div className="auth-form-container pb-4">
          <div className="modal-header pe-2 py-1 flex-r-header-modal">
            <CloseModalBtn onClick={closeModalConnexion} />
          </div>
          <div className="content-logo-auth pb-4 pt-1">
            <img src={Logo} alt="logo" className="logo-auth-page" />
          </div>
          <div className="tabs-main-content">
            <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li 
                className={`
                  tab-item 
                  ${index === key && 'tab-item-active'}
                `}
                onClick={() => go(key)}
                key={key}
              >
                {tab}
              </li>
            ))}
            </ul>
          </div>
          <div className="pt-5">
            <div className="tabs-content-value">
              <Component closeModalConnexion={closeModalConnexion} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnexionModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding:"0%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "85%",
    zIndex: 99999,
  },
};
