import React from "react";
import StarRatingComponent from "react-star-rating-component";
import AvisProductModal from "./modal/AvisProductModal";

function AvisProduct({ product }: { product: any }) {
  const [show, setShow] = React.useState(false);
  // const {user} = useAppSelector((s) => s?.user)
  // const {
  //   data = {
  //     data: [],
  //     states: [
  //       { percent: 0, stars: 5, count: 0 },
  //       { percent: 0, stars: 4, count: 0 },
  //       { percent: 0, stars: 3, count: 0 },
  //       { percent: 0, stars: 2, count: 0 },
  //       { percent: 0, stars: 1, count: 0 },
  //     ],
  //     rateCount: 0,
  //     avreageRate:0,
  //   },
  //   isLoading,
  // } = useGetRateListByProductQuery({
  //   product: product?._id,
  // });
  return (
    <div className="container-product-item-page mb-3">
      <h3 className="title-section-product-item">Avis clients</h3>
      <div className="row py-4 b-b-1 mb-3">
        <div className="col-lg-3 col-md-4 col-avis-produit mb-3">
          <p className="title-rate-product-item mb-0">NOTE MOYENNE</p>
          <div className="content-note-product-item-rate">
            <p className="note-product-item-rate">
              {/* {data?.avreageRate || 0} */} 4
              <span style={{ color: "#65635F", fontSize: 30 }}>/5</span>
            </p>
          </div>
          <div className="content-rate-product">
            <StarRatingComponent
              name="rate2"
              starCount={5}
              // value={data?.avreageRate}
              value={4}
              starColor="#DDB012"
              emptyStarColor="#D4D6D5"
            />
          </div>
          {/* {data?.rateCount && data?.rateCount > 0 && ( */}
            <p className="text--rate-product-item">
              sur 
              {/* {data?.rateCount} */}
             17  avis
            </p>
          {/* )} */}
        </div>
        <div className="col-lg-3 col-md-4 col-avis-rate-produit mb-3">
          <div className="content-col-avis-rate-produit">
            {/* {[...data?.states]
              ?.sort((a, b) => b?.stars - a?.stars)
              ?.map((item) => ( */}
                <div className="col-avis-rate-produit-item mb-2">
                  <p
                    className="note-avis-libelle mb-0"
                    // style={{ marginRight: item?.stars === 1 ? ".5rem" : "0" }}
                  >
                    {/* {item?.stars} */}
                     5 étoiles
                     {/* {item?.stars !== 1 ? "s" : ""} */}
                  </p>

                  <div className="progress content-note-rate-avis">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `100%` }}
                    />
                  </div>

                  <p className="note-avis-libelle mb-0">4</p>
                </div>
              {/* ))} */}

            <div className="col-avis-rate-produit-item mb-2">
              <p className="note-avis-libelle mb-0">4 étoiles</p>
              <span className="content-note-rate-avis"></span>
              <p className="note-avis-libelle mb-0">6</p>
            </div>
            <div className="col-avis-rate-produit-item mb-2">
              <p className="note-avis-libelle mb-0">3 étoiles</p>
              <span className="content-note-rate-avis"></span>
              <p className="note-avis-libelle mb-0">2</p>
            </div>
            <div className="col-avis-rate-produit-item mb-2">
              <p className="note-avis-libelle mb-0">2 étoiles</p>
              <span className="content-note-rate-avis"></span>
              <p className="note-avis-libelle mb-0">0</p>
            </div>
            <div className="col-avis-rate-produit-item mb-2">
              <p className="note-avis-libelle mb-0">1 étoiles</p>
              <span className="content-note-rate-avis"></span>
              <p className="note-avis-libelle mb-0">0</p>
            </div>
          </div>
        </div>
      </div>
      {/* {!!user?._id && ( */}
        {/* <div className="row py-4 b-b-1 mb-3">
          <h3 className="solicitation-title">Évaluer ce produit</h3>
          <div className="solicitation-subtitle">
            Partagez votre opinion avec les autres clients
          </div>
          <div className="btn-solicitation-container">
            <button
              className="btn btn-solicitation btn-theme-outline"
              // onClick={() => setShow(true)}
            >
              Donner mon avis client
            </button>
          </div>
          <AvisProductModal show={show} setShow={setShow} product={product} />
        </div> */}
      {/* )} */}

      <div className="row py-4">
        <div className="col-lg-10 col-md-12 col-table-rate mb-3">
          {/* {!isLoading &&
            (data?.data?.length
              ? data?.data?.map((item, i) => ( */}
                  <div className="row b-1-color pt-4">
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {/* {i === 0 &&  */}
                        <p className="title-table-rate">AUTEUR</p>
                        {/* } */}

                        <div className="content-text-value-table-rate">
                          <p className="text-value-table-rate mb-0">
                            {/* {item?.annonyme
                              ? "Anonyme"
                              : item?.user?.prenom + " " + item?.user?.nom} */}
                              Anonyme A
                          </p>
                          <p className="text-libelle-table-rate mb-0">
                            {/* {moment(item?.createdAt).format("DD MMMM YYYY")} */}
                            12 Décembre 2022
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {/* {i === 0 && */}
                         <p className="title-table-rate">NOTES</p>
                         {/* } */}
                        <div className="content-text-value-table-rate">
                          <p className="text-value-table-rate mb-0">
                            {/* {item?.stars} */}
                           4 /5
                          </p>
                          <div className="content-rate-product">
                            <StarRatingComponent
                              name="rate2"
                              starCount={5}
                              value={4}
                              starColor="#DDB012"
                              emptyStarColor="#D4D6D5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {/* {i === 0 &&  */}
                        <p className="title-table-rate">MOTIF</p>
                         {/* } */}
                        <div className="content-text-value-table-rate">
                          <p className="text-libelle-table-rate mb-0">
                            {/* {item?.motif || ""} */}
                            Suite à une commande 
                          </p>
                          <p className="text-libelle-table-rate mb-0">
                            du 12 Décembre 2022
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {/* {i === 0 && ( */}
                          <p className="title-table-rate">Commentaire</p>
                        {/* )} */}
                        <div className="content-text-value-table-rate">
                          <p className="text-value-table-rate mb-0">
                            {/* {getLabelRate(item?.stars)} */}
                            Bien
                          </p>
                          <p className="text-libelle-table-rate mb-0">
                            {/* {item?.comment} */}
                            Le produit est parfait
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                {/* ))
              : null)} */}
        </div>
      </div>
    </div>
  );
}

export default AvisProduct;
