import React, { useEffect, useState } from "react";
import PanierItemTable from "../../Panier/PanierItemTable";
import { AppStorage } from "../../../../../utils/storage";

const PanierValidStep = () => {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string | null>();
  const [total, setTotal] = React.useState<any>(0);

  let paniers = JSON.parse(AppStorage.getItem('panier'));

  useEffect(()=>{

    let somme = 0 

    paniers.forEach(element => {
      somme = somme + (element?.articlePrice * element?.quantite)
    });

    setTotal(somme)

  },[paniers]);


  return (
    <div className="content-form-register-tabs my-5">
        <div className="cart-items-table-content cart_step_wrapper">
            <PanierItemTable produits={paniers} />
        </div>
    </div>
  );
};

export default PanierValidStep;
