/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  showModal,
  validatePassword,
  validatePhone,
} from "../../../utils/Utils";
import { RegisterFormData } from "../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../utils/api/auth/auth.api";
import { Color } from "../../../utils/theme";
import { useGetZoneListByVisitorQuery } from "../../../utils/api/zone/zone.api";
import {VendeurApikey} from "../../../utils/http";

function UseRegisterForm(closeModalConnexion: () => void) {
  const [code, setCode] = useState<string>("");
  const [code1, setCode1] = useState<string>("");
  const [code2, setCode2] = useState<string>("");
  const validationSchema = yup.object().shape({
    firstName: yup.string().required().label("Le prénom"),
    lastName: yup.string().required().label("Le nom"),
    // telephone:validatePhone(yup, code).required(),
    phone: yup.string().required().required().label("Le téléphone"),
    email: yup.string().email().required().label("L'email"),
    password: validatePassword(yup),
    confirmPassword: yup.string()
        .oneOf(
          [yup.ref("password"), ''],
          "Les mots de passe doivent correspondre"
        )
        .required()
        .label("La confirmation du mot de passe"),
    // adresse: yup.string().required().label("L'adresse").nullable(),
        
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<RegisterFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const [dataForm, setDataForm] = useState<any>();
  const [level, setLevel] = useState(0);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  const [address, setAddress] = useState("");
  const {data:zones} = useGetZoneListByVisitorQuery()
  //  Calling the Register Mutation
  const [registerUser, { isLoading, isSuccess, error, isError }] =
    useRegisterUserMutation();

  const [phone, setPhone] = useState<string>("");
  const [phone1, setPhone1] = useState<string | null>(null);
  const [phone2, setPhone2] = useState<string | null>(null);

  // useEffect(() => {
  //   if (zones?.length) {
  //     // console.log("zones",zones)
  //     setOptions(zones?.map((z) => {
  //       return {
  //         label: z?.ville,
  //         value: z?._id
  //       }
  //     }))
  //   }
  // }, [zones])

  // useEffect(() => {
  //   if (dataForm?.firstName && !dataForm?.adresses?.length) {
  //     setValue(`adresses.${0}.firstName`, dataForm?.firstName);
  //     setValue(`adresses.${0}.lastName`, dataForm?.lastName);
  //     setValue(`adresses.${0}.telephone1`, dataForm?.phone);
  //     setValue(`adresses.${0}.isDefault`, true);
  //     setPhone1("+" + dataForm?.phone);
  //   }
  // }, [dataForm]);

  const handleSelectZone = (zone) => {
    setOption(zone)
    setValue(`adresses.${0}.zone`, zone?.value);
  }
  // const onChangeAddress = (val: {
  //   rue: string;
  //   latitude: string;
  //   longitude: string;
  //   ville: string;
  //   country: string;
  // }) => {
  //   setAddress(val?.rue);
  //   setValue(`adresses.${0}.adresse`, val?.rue);
  //   setValue(`adresses.${0}.latitude`, val?.latitude);
  //   setValue(`adresses.${0}.longitude`, val?.longitude);
  //   // if (val?.ville) {
  //   //   setValue(`adresses.${0}.ville`, val?.ville);
  //   // }
  // };

  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str?.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str?.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    setPassword(password);
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone("+" + item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("phone", item);
    } else {
      setValue("phone", "");
    }
  };

  const handleChangePhone1 = (item, country) => {
    setCode1(country?.countryCode);
    setPhone1("+" + item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`adresses.${0}.telephone1`, item);
    } else {
      setValue(`adresses.${0}.telephone1`, "");
    }
  };

  const handleChangePhone2 = (item, country) => {
    setCode2(country?.countryCode);
    setPhone2("+"+item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`adresses.${0}.telephone2`, item);
    } else {
      setValue(`adresses.${0}.telephone2`, "");
    }
  };


  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: any) => {
    // console.log("data register", data);
    // setDataForm(data);
    data.adresse = ''
    data.user_type = 'ACHETEUR'
    
    const res = await registerUser(data);

    if ("data" in res) {
      Swal.fire({
        icon: "success",
        title: "Inscription reussie!",
        text: "Vous pouvez vous connecter en utilisant votre numero de téléphone et votre mot de passe.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 5000,
      }).then(() => {
        closeModalConnexion()
        navigate("/");
      });
    }

    if ("error" in res) {
      const err = res.error as any;
      let message = err?.data?.message
				? err?.data?.message
				: err?.status < 500
				? "Cet téléphone est déjà associé à un compte"
				: `Une erreur de statut ${err?.status} est survenue.`;

    }
    
    
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    modalConnexion,
    setModalConnexion,
    level,
    dataForm,
    password,
    phone1,
    phone2,
    handleChangePhone1,
    handleChangePhone2,
    //onChangeAddress,
    address,
    handleSelectZone,
    options,
    option,
    Controller,
    control
  };
}

export default UseRegisterForm;
