import React, { useState } from 'react'
import { AlertInfo } from '../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from '../../../common/TableSkeleton';
import { FaTrash } from "react-icons/fa";
import { AiOutlineHeart } from 'react-icons/ai';
import product from "../../../../assets/appImages/product_image.png";
import { getImage } from "../../../../utils/Utils";
import Empty from "../../../../assets/appImages/category.png"
import { onSetPanier } from "../../../../redux/slice/User.slice";
import { AppStorage } from "../../../../utils/storage";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { Color } from "../../../../utils/theme";
import Swal from "sweetalert2";


function PanierItemTable({produits}) {
  const dispatch = useAppDispatch();

  const [pages, setPages] = useState(CommandeDetailTableData)

  const onDeleteProduit = async (item: any) => {
    let panier = JSON.parse(AppStorage.getItem('panier'));
    
   // dispatch(onSetPanier( panier?.filter((x)=>(x.id != item?.id))));

    await Swal.fire({
      title: 'Êtes-vous sure de suprimer cette produit de votre panier ',
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return  dispatch(onSetPanier( panier?.filter((x)=>(x.id != item?.id)))) ;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "archiver");

      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Produit suprimer du panier avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      
    });
    
  }

    const actionFormatter = (cell: any, row: any) => {
      return (
        <>
          <div className="table-actions-btn-container-commandes d-flex justify-content-center align-items-center gap-3">
            <div className="container-btn-action-icon-modal">
              <button className="btn btn-action-modal-icon with-tooltip" style={{ color: "#797A6F" }}>
                {/* <AiFillHeart style={{ color: "#E58924" }} /> */}
                <AiOutlineHeart />
            </button>
            </div>
            <div className="container-btn-action-icon-modal">
                <button
                    type='button'
                    className="btn with-tooltip btn-action-icon-delete"
                    data-tooltip-content="Supprimer"
                    style={{ color: "#E53E3E" }}
                    onClick={()=>onDeleteProduit(row)}
                >
                    <FaTrash />
                </button>
            </div>
          </div>
        </>
      );
    };

    const productFormatter = (cell: any, row: any) => {
      return(
        <div className="d-flex align-items-center gap-2">
            <div className="product-image-container">
                <img 
                    src={row?.variations ? getImage(row?.variations[0]?.images[0]?.image) : Empty} 
                    alt="image"
                    className="cart-image" 
                />
            </div>
            <div>
              <p className="product-name-panier mb-1">
                {row?.nom}
            </p>
            <p className='product-category-name mb-0'>{row?.categorie?.nom}</p>
            </div>
        </div>
      )
    }

    const quantityFormatter = (cell: any, row: any) => {
       // const [countQ, setCountQ] = React.useState<number>(1)

       
        return(
          <div className="d-flex align-items-center justify-content-center">
              <div className='row row-quantity'>
                <div className='col-md-4'>
                    <button 
                        className="action-btn"
                      //  onClick={() => setCountQ(countQ - 1)}
                    > -
                    </button>
                </div>
                <div className='col-md-4 btn-wrapper'>
                    {row?.quantite}
                </div>
                <div className='col-md-4'>
                    <button 
                        className="action-btn"
                       // onClick={() => setCountQ(countQ + 1)}
                    >    +
                    </button>
                </div>
              </div>
          </div>
        )
      }

      const totalFormatter = (cell: any, row: any) => {
 
         return(
           <div className="d-flex align-items-center justify-content-center">
              {row?.prix * row?.quantite}
           </div>
         )
       }
 

    const columns = [
      {
        dataField: "articleName",
        text: "Produits",
        formatter: productFormatter
      },
      {
        dataField: "prix",
        text: "Prix",
      },
      {
        dataField: "quantite",
        text: "Quantité",
        //formatter: quantityFormatter
      },
      {
        dataField: "total",
        text: "Total",
        formatter: totalFormatter
      },
      {
        dataField: "id",
        text: "",
        formatter: actionFormatter
      },
    ];

    return (
      <>
          <BootstrapTable
              keyField="id"
              data={produits || []}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive panier-table"
              noDataIndication={() => (
                <AlertInfo message="Votre panier est vide" />
              )}
          />
      </>
    );
}

export default PanierItemTable

const CommandeDetailTableData = [
    {
        id: 1,
        image: product,
        product_name: "Crown Summit Backpack",
        product_category: "Marque: Home ",
        marque: "Channel",
        quantity: 1,
        price: "500€",
        total: "500€",
    },
    {
        id: 2,
        image: product,
        product_name: "Crown Summit Backpack",
        product_category: "Marque: Home ",
        marque: "Channel",
        quantity: 1,
        price: "500€",
        total: "500€",
    },
]

