import React,{useEffect} from "react";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import "./ValidateCommand.css";
import BreadCrumb from "../../../shared/BreadCrumb";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import PanierValidStep from "./steps/PanierValidStep";
import AdresseLivraison from "./steps/AdresseLivraison";
import ModePaiement from "./steps/ModePaiement";
import FooterPage from "../../../auth/FooterPage";
import  UseAddOrUpdateOrderForm  from "./requestForm/UseAddOrUpdateOrderForm";
import { AppStorage } from "../../../../utils/storage";
import { useAppSelector } from "../../../../redux/hook";

const steps = [
   { id: "panier-validation", Component: PanierValidStep },
   { id: "address-livraison", Component: AdresseLivraison },
    { id: "mode-paiement", Component: ModePaiement },
]
const tabs = [
    "1",
    "2",
    "3"
]


function ValidateCommand() {

    const { user } = useAppSelector((s) => s?.user);

    const [total, setTotal] = React.useState<any>(0);
  
    let paniers = JSON.parse(AppStorage.getItem('panier'));
  
    useEffect(()=>{
  
      let somme = 0 
  
      paniers.forEach(element => {
        somme = somme + (element?.prix * element?.quantite)
      });
  
      setTotal(somme)
  
    },[paniers]);





    const {
        step,
        navigation: {go, next, previous},
        index
    }: useStepType = useStep({ initialStep: 0, steps});
    const {Component} = step;

    const {
        register,
        onSubmit,
        isLoading,
        errors,
        handleChangePhone,
        phone,
        setValue
    } = UseAddOrUpdateOrderForm(paniers,total);


    useEffect(()=>{

        let produit_paniers = JSON.parse(AppStorage.getItem('panier'));
        let code_commande: number | string = new Date().getTime();
        let somme = 0 ;
  
        produit_paniers.forEach(element => {
            somme = somme + (element?.prix * element?.quantite)
        });

        setValue("vendeur", produit_paniers[0]?.vendeur?.id);
        setValue("produits", produit_paniers );
        setValue("nom", user?.prenom+" "+user?.nom );
        setValue("telephone", user?.telephone );
        setValue("adresse", user?.adresse );
        setValue("code_commande", code_commande.toString() );
        setValue("user", user?.id);
        setValue("statut", 'EN_COURS_DE_TRAITEMENT' );
        setValue("montant_total", somme);
    
      },[user]);

    return(
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <div className="container pt-4">
                    {index === 0 &&
                    <BreadCrumb 
                        pageName="mon panier" 
                        secondPage="valider mon panier"
                    />
                    }
                    {index === 1 &&
                    <BreadCrumb 
                        pageName="mon panier" 
                        secondPage="Adresse de livraison"
                    />
                    }
                    {index === 2 &&
                    <BreadCrumb 
                        pageName="mon panier" 
                        secondPage="Finaliser"
                    />
                    }
                    <div className="validate-command-content pt-5 pb-5">
                        <div className="row row-validate">
                            
                            <div className="col-md-9 validate-wrapper">
                                <form onSubmit={onSubmit}>
                                    <div className="tabs-wrapper pb-4">
                                        <ul className="tabs-container">
                                        {tabs.map((tab, key) => (
                                            <li 
                                                className={`
                                                    tab-item it${key}
                                                    ${index === key ? 'tab-item-active' : ''}
                                                `}
                                                onClick={() => go(key)}
                                            >
                                                {tab}
                                                {key === 0 &&
                                                    <span className="tab-indicator">
                                                        Panier
                                                    </span>}
                                                {key === 1 &&
                                                    <span className="tab-indicator">
                                                        Adresse de livraison
                                                    </span>}
                                                {key === 2 &&
                                                    <span className="tab-indicator">
                                                        Finaliser
                                                    </span>}
                                            </li>
                                        ))}
                                            <div className="progress validate-progress">
                                                {index === 0 &&
                                                <div 
                                                    className="progress-bar custom-progress" 
                                                    role="progressbar" 
                                                    aria-label="Basic example" 
                                                    style={{ width: '0%' }}
                                                    aria-valuenow={25} 
                                                    aria-valuemin={0} 
                                                    aria-valuemax={100}>
                                                </div>}
                                                {index === 1 &&
                                                <div 
                                                    className="progress-bar custom-progress" 
                                                    role="progressbar" 
                                                    aria-label="Basic example" 
                                                    style={{ width: '53%' }}
                                                    aria-valuenow={75} 
                                                    aria-valuemin={0} 
                                                    aria-valuemax={100}>
                                                </div>}
                                                {index === 2 &&
                                                <div 
                                                    className="progress-bar custom-progress" 
                                                    role="progressbar" 
                                                    aria-label="Basic example" 
                                                    style={{ width: '100%' }}
                                                    aria-valuenow={100} 
                                                    aria-valuemin={0} 
                                                    aria-valuemax={100}>
                                                </div>}
                                            </div>
                                        </ul>
                                    </div> 
                                    <Component register={register} errors={errors} handleChangePhone={handleChangePhone} phone={phone} />
                                    <div className="action-btn-content d-flex justify-content-end align-items-center gap-3 pt-4">
                                        {(index >= 1 && paniers.length > 0)   &&
                                        <button
                                            className="step-action-btn validate-go-back-btn"
                                            // disabled={index === 2}
                                            type="button"
                                            onClick={previous}
                                        >
                                            Précédent
                                        </button>
                                        }
                                        {(index === 0 && paniers.length > 0) &&
                                        <button
                                            className="step-action-btn parcours-submit-btn"
                                            type="button"
                                            onClick={next}
                                        >
                                            Suivant
                                        </button>}
                                        {(index === 1 && paniers.length > 0)  &&
                                        <button
                                            className="step-action-btn parcours-submit-btn"
                                            type="button"
                                            onClick={next}
                                        >
                                            Suivant
                                        </button>}
                                        {(index === 2 && paniers.length > 0)  &&
                                        <button
                                            className="step-action-btn parcours-submit-btn"
                                            type="submit"
                                        >
                                            Valider ma commande
                                        </button>}
                                    </div>
                                </form>
                            </div>
                            
                            <div className="col-md-3">
                                <div className="cart-card">
                                    <h4 className="title pb-4">
                                        Total du panier
                                    </h4>
                                    {/* <div className="subtotal d-flex align-items-center justify-content-between pb-3">
                                        <span className="sm-title">Sous-total</span>
                                        <span className="sm-value">1000</span>
                                    </div> */}
                                    <div className="separator"></div>
                                    <div className="total d-flex align-items-center justify-content-between pt-3">
                                        <span className="sm-title">TOTAL</span>
                                        <span className="sm-value total">{total}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterPage /> */}
            <FrontFooter />
        </div>
    )
}

export default ValidateCommand;