/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  closeModal, 
  showModal , 
  getInTechTel , 
  getInTechPhone
} from "../../../../../utils/Utils";
import { OrderFormData , Order } from "../../../../../utils/api/order/order.type";
import { useAddOrEditOrderMutation , useDeleteOrderMutation } from "../../../../../utils/api/order/order.api";
import { Color } from "../../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../../utils/helpers";
import { AppStorage } from "../../../../../utils/storage";
import { useAppSelector } from "../../../../../redux/hook";
import { ApiBaseUrl, IntechApikey, AppBaseUrl, Env } from "../../../../../utils/http";


function UseAddOrUpdateOrderForm(panier?:any ,total?:number,order?: Order) {

  const { user } = useAppSelector((s) => s?.user);
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string | null>(null);
	const [mode, setMode] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const [infosPayment, setInfosPayment] = useState<any>();


  const validationSchema = yup.object().shape({
    // titre: yup.string().required().label("Le titre"),
    // description: yup.string().required().label("Le description"),
    // montant: yup.string().required().label("Le montant"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm<OrderFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<{label:string,value:string} | undefined>()
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();


  const [addOrUpdateOrder, { isLoading, isSuccess, error, isError }] =
  useAddOrEditOrderMutation();

  const handleChangeIcone = (e) => { 
    let files = e.target.files[0];
    //setValue("icone" , files)
  }

  const handleChangeBanniere = (e) => { 
    let files = e.target.files[0];
   // setValue("banniere" , files)
  }

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", item);
    } else {
      setValue("telephone", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Order ${order ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 6000,
      }).then(() => {
        // setModalConnexion(true)
        navigate('/admin/orders')
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("The email has already been taken.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });

      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {
    setPhone(user?.telephone);
  }, [user]);

  useEffect(() => {

    if (order?.slug) {
      let fields: (keyof OrderFormData)[] = [

        'slug',	
        'statut',
        'montant_total' ,	
        'code_commande' ,	
        'mode_de_paiement',	
        'is_paid'	,
        'devise',	
        'confirmation_client'	,
        'transaction_intech_code',	
        'user' ,	
        'vendeur' ,	
        'produits' ,
      ];
      for (let field of fields) {
        register(field);
      //  setValue(field, order[field])
        
      }
      

    }
  }, [order]);



  const onSubmit = async (data: OrderFormData) => {
      let code_commande: number | string =
          new Date().getTime();
      let somme = 0 
      let apiCash = data?.mode_de_paiement
      setMode(apiCash);
		  setLoading(true);

      try {
        const order = await addOrUpdateOrder({ data: data });
        console.log('order',order)
        let externalTransactionId: number | string =
          new Date().getTime();
        // if (order) {
        //   externalTransactionId = order?.slug as string;
        // }
        let amount = 1;
        // if (Env === 'prod') {
        //   amount = user?.price;
        // }
        if (amount === 10 && apiCash === "BANK_CARD_API_CASH_OUT") {
          amount = 100;
        }
  
        if (user?.is_paid === true) {
          Swal.fire({
            icon: "success",
            title: `Commande réussie!`,
            text: "Votre Commande a été envoyées avec succès.",
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 5000,
          }).then(() => {
            //closeModal("successModal");
            navigate("/panier", { replace: true });
          });
        }else if(data?.mode_de_paiement == "CASH"){
          Swal.fire({
            icon: "success",
            title: `Commande réussie!`,
            text: "Votre Commande a été envoyées avec succès.",
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 5000,
          }).then(() => {
            //closeModal("successModal");
            navigate("/panier", { replace: true });
          });
        } else {
          // let amount = Env !== "dev" ? offer?.price : 5;
          // if (amount === 10 && apiCash === "BANK_CARD_API_CASH_OUT") {
          //   amount = 100;
          // }
          const tel = user?.telephone;
          let dataSend = {
            amount: amount,
            phone: "",
            codeService: apiCash,
            externalTransactionId,
            callbackUrl: `${ApiBaseUrl}/api/callback/intech/`, 
            apiKey: IntechApikey,
            data: {
              env: "test",
            },
            sender: "Easy Market",
            merchantName: "Easy Market",
            operationDescription:
              "Paiement sur la plateforme Easy Market avec intech",
            successRedirectUrl: `${AppBaseUrl}/mon-compte/commandes?status=success`,
            errorRedirectUrl: `${AppBaseUrl}/mon-compte/commandes?status=error`,
          };
          if (apiCash !== "BANK_CARD_API_CASH_OUT") {
            dataSend.phone =
              getInTechPhone(tel)?.toString() ?? "";
          } else {
            dataSend.phone =
              getInTechTel(tel)?.toString() ?? "";
          }
          const requestOptions: RequestInit = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(dataSend),
          };
          if (!isError) {
            const res = await fetch(
              "https://api.intech.sn/api-services/operation",
              requestOptions
            ).then((res) => {
              if (res.ok) return res.json();
              throw {
                data: {
                  message: `Une erreur est survenue code: ${res.status}`,
                },
              };
            });
            console.log("res", res);
            setLoading(false);
            if (res?.error) {
              let message = "";
              if (res.data) {
                for (let [k, v] of Object.entries(
                  res.data
                )) {
                  if (Array.isArray(v)) {
                    message = `${message}\n${k}: ${v.join(
                      ", "
                    )}`;
                  }
                }
              }
              if (message) {
                // toast(message, true);
                Swal.fire({
                  icon: "error",
                  title: message,
                  showConfirmButton: false,
                  timer: 5000,
                });
              } else if (res.msg) {
                // toast(res.msg, true);
                Swal.fire({
                  icon: "error",
                  title: res.msg,
                  showConfirmButton: false,
                  timer: 5000,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: res?.msg
                    ? res?.msg
                    : `Erreur de statut code ${res?.code} est survenue!`,
                  showConfirmButton: false,
                  timer: 5000,
                });
              }
              return false;
            } else {
              const { externalTransactionId } = res.data;
              setInfosPayment(res.data);
              //closeModal("paymentMethod");
              //closeModal("successModal");
              Swal.fire({
                icon: "success",
                title: res?.msg,
                showConfirmButton: false,
                timer: 5000,
              }).then(() => {
                if (externalTransactionId) {
                  console.log('show modale')
                 // showModal("statusPaymentModal");
                }
              });
  
              return true;
            }
          }
        }
      } catch (error: any) {
        setLoading(false);
        // console.log("error", error);
        const data = error?.data as any;
  
        let m = data?.data?.message;
        if (!m) m = data?.message;
        if (!m && data) m = data["message "] || null;
        if (m) {
          // toast(m, true);
          Swal.fire({
            icon: "error",
            title: m,
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          // toast(, true);
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue",
            showConfirmButton: false,
            timer: 5000,
          });
        }
        return false;
      }
  };


  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeIcone,
    handleChangeBanniere,
    handleChangePhone,
    phone
  };
}

export function DeleteOrder({ item }: { item: Order }) {
  console.log('item',item)
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteOrderMutation();
  const onDelete = useDelete<Order>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette commande",
    successMessage: "Commande supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export default UseAddOrUpdateOrderForm;
