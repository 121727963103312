import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./WhoWeAre.css";
import illustration from "../../../assets/appImages/illustration.png";
import founder1 from "../../../assets/appImages/founder_1.png";
import founder2 from "../../../assets/appImages/founder_2.png";
import { AiOutlineGoogle, AiOutlineTwitter, AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { TeamData } from "./data";
import { Link } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";


const CustomBeautyTitle = ({title}: {title: string}) => {
    return(
        <h2 className="custom-esb-title">
            {title}
        </h2>
    )
}

const TeamCard = (
    {photo, name, role, twitter, facebook, linkedin}:
    {
        photo?: string, 
        name: string, 
        role: string, 
        twitter?: string, 
        facebook?: string, 
        linkedin?: string
    }
) => {
    return(
        <div className="team-card">
            <span className="photo-wrapper">
                <img src={photo} alt="photo" />
            </span>
            <span className="name">{name}</span>
            <span className="role">{role}</span>
            <div className="social-networks d-flex align-items-center justify-content-center gap-2">
                <Link
                    target="_blank"
                    to={`#`}
                >
                    <AiOutlineGoogle />
                </Link> 
                <Link
                    target="_blank"
                    to={`${twitter}`}
                >
                    <AiOutlineTwitter />
                </Link>
                <Link
                    target="_blank"
                    to={`${facebook}`}
                >
                    <AiFillFacebook />
                </Link>
                <Link
                    target="_blank"
                    to={`${linkedin}`}
                >
                    <AiFillLinkedin />
                </Link>
            </div>
        </div>
    )
}


function WhoWeAre() { 
    return(
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content who-we-are-page pb-5 pb-5">
                <h1 className="custom-page-title">
                    Qui sommes-nous ?
                </h1>
                <div className="who-we-are">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="illustration-container">
                                    <img src={illustration} alt="image" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <CustomBeautyTitle 
                                    title="Qui sommes nous?"
                                />
                                <h3 className="custom-title">Easy Market</h3>
                                <div className="custom-content">
                                    Easymarket, une solution en marque blanche personnalisable qui vous permet de lancer 
                                    votre site ecommerce en moins de 2 semaines.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plateform-founders">
                    <div className="container">
                        <CustomBeautyTitle 
                            title="Qui sommes nous?"
                        />
                        <h3 className="custom-title">Les fondateurs</h3>
                        <div className="row pt-4">
                            <div className="col-md-6">
                                <div className="founder-card">
                                    <span className="photo-wrapper">
                                        <img src={founder1} alt="founder" />
                                    </span>
                                    <span className="name">
                                        Adjaratou K. NDIAYE
                                    </span>
                                    <span className="description">
                                        Titulaire d’un master un management et d’un mastère spécialisé entrepreneur, 
                                        Khady est Business Developer à Volkeno et entrepreneur depuis 2018.
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="founder-card">
                                    <span className="photo-wrapper">
                                        <img src={founder2} alt="founder" />
                                    </span>
                                    <span className="name">
                                        Volkeno
                                    </span>
                                    <span className="description">
                                        Volkeno est une entreprise de services numériques qui accompagne depuis 2015 
                                        entrepreneurs et entreprises dans la réalisation de produits et services à 
                                        forte valeur ajoutée.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="our-team">
                    <div className="container">
                        <CustomBeautyTitle 
                            title="Team"
                        />
                        <h3 className="custom-title">Notre équipe</h3>
                        <div className="row pt-4">
                        {!!TeamData &&
                           TeamData.map((member, key) => {
                            return(
                                <div className="col-md-3 card-wrapper">
                                    <TeamCard 
                                        photo={member.photo}
                                        name={member.name}
                                        role={member.role}
                                        twitter={member.twitter}
                                        facebook={member.facebook}
                                        linkedin={member.linkedin}
                                    />
                                </div>
                            )
                           })
                        }
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterPage /> */}
            <FrontFooter />
        </div>
    )
}

export default WhoWeAre;