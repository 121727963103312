import React ,{useState,useEffect} from "react";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import FilterItems from "../user/ParcoursAchat/FilterItems";
import { Promotion } from "../../utils/api/promotion/promotion.type";
import { ProductData, ProductItem } from "../HomePage/HomePage";
import FooterPage from "../auth/FooterPage";
import { useGetPromotionListQuery } from "../../utils/api/promotion/promotion.api";
import { useSearchParams , useLocation } from "react-router-dom";
import BreadCrumb from "../shared/BreadCrumb";
import "../user/ParcoursAchat/ParcoursAchat.css";
import { useGetProductListQuery} from "../../utils/api/product/product.api";
import { usePagination, useSearch } from "../../utils/hooks";

let options = [
    {
      label: "Nombre d'avis",
      value: "avreageRate",
    },
    {
      label: "Date de création",
      value: "createdAt",
    },
    {
      label: "Nom",
      value: "name",
    },
    {
      label: "Note moyenne",
      value: "rateCount",
    },
    {
      label: "Prix",
      value: "price",
    },
];


function OurProduct() {

    const { limit, page, onChange } = usePagination(10);

    let { state } = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const [searchCategorys, setSearchCategorys] = useState<any>([]);
    const [produits, setProduits] = useState<any>([]);

    const [value, setValue] = React.useState<any>({
        min: searchParams?.get("min") || 0,
        max: searchParams?.get("max") || 9000000,
      });

    const [query, setQuery] = React.useState({
        category: searchParams?.get("category") || "",
        min: searchParams?.get("min") || "",
        max: searchParams?.get("max") || "",
        orderBy: searchParams?.get("orderBy") || "",
    });

    const { data , refetch } = useGetProductListQuery({
      page: page,
      limit: limit
    });
 

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let queryCopy = { ...query };
        queryCopy = {
        ...queryCopy,
        orderBy: e?.target?.value,
        };
        setQuery(queryCopy);
    };

    useEffect(() => {
        setProduits(data?.results);
    }, [data]);

    useEffect(() => {

        if(searchCategorys?.length > 0 && data){
            setProduits(data?.results?.filter((x) => ((searchCategorys.includes((x?.categorie?.id?.toString()))) && (x?.prix >= value?.min && x?.prix < value?.max ))));
        }
        if(searchCategorys?.length == 0)
        {
            setProduits(data?.results?.filter((x) => (x?.prix >= value?.min && x?.prix < value?.max )));
        }

    }, [searchCategorys , value  ]);

    useEffect(()=>{
        if(state?.catgoryId){
            setProduits(data?.results?.filter((x) => ( x?.categorie?.id?.toString() == state?.catgoryId )));
        }
    },[state]);

    


    return (
        <>
            <div className="landing-page-component">
                <FrontHeader />
                <div className="page-content">
                    <div className="container-easy-market">
                    <div className="row row-achat-page mb-5">
                        <FilterItems
                        value={value}
                        setValue={setValue}
                        searchCategorys={searchCategorys}
                        setSearchCategorys={setSearchCategorys}
                        query={query}
                        setQuery={setQuery}
                        catgoryId={state?.catgoryId}
                        />
                        <div className="col-lg-9 col-md-7 col-right-achat-page">
                        <div className="content-col-right-achat-page">
                            <div
                            className="container-home-page mb-3"
                            style={{ minHeight: "51rem" }}
                            >
                            {/* <div className="content-btn-title-home-page flex-r py-3">
                                <div className="tab-dash-admin-filter-container">
                                <form className="row g-3 tab-dash-admin-filter-form">
                                    <div className="col-auto">
                                    <label
                                        htmlFor="filtreProduit"
                                        className="tab-dash-admin-filter-label"
                                        style={{ fontWeight: 700, color: "#000" }}
                                    >
                                        Trier par
                                    </label>
                                    </div>
                                    <div className="col-auto">
                                    <select
                                        className="form-control filter-select-product"
                                        value={searchParams?.get("orderBy") || ""}
                                        onChange={(e) => handleSelect(e)}
                                    >
                                        <option value="">Pertinence</option>
                                        {options?.map((opt, i) => (
                                        <option value={opt?.value} key={i}>
                                            {" "}
                                            {opt?.label}{" "}
                                        </option>
                                        ))}
                                    </select>
                                    </div>
                                </form>
                                </div>
                            </div> */}
                            <div className="row mt-3">
                                {!! produits && produits?.map((product, key) => {
                                    return(
                                    <div
                                        className="col-md-4 mb-3 col-product-item d-flex"  
                                        key={key}
                                    >
                                        <ProductItem item={product} key={key} />
                                    </div>
                                    )
                                })

                                }
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <FooterPage /> */}
                    </div>
                </div>
                <FrontFooter />
            </div>
        </>
    )
}

export default OurProduct