import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "./Contact.css";
import { Input } from "../../common/Input";

function Contact() {

    return(
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <h1 className="custom-page-title">
                    Contact
                </h1>
                <div className="container pt-4">
                    <div className="row row-contact">
                        <div className="col-md-6 col-infos">
                            <div className="custom-item">
                                <div className="item-sm-title">
                                    ADRESSE
                                </div>
                                <div className="item-sm-value">
                                    Sipres 2, Cité Mourtada Villa Numéro 2  <br /> 
                                    derrière Hypermarché Exclusif
                                </div>
                            </div>
                            <div className="custom-item pt-3">
                                <div className="item-sm-title">
                                    MOBILE
                                </div>
                                <div className="item-sm-value">
                                    (+221) 77 531 18 46
                                </div>
                            </div>
                            <div className="custom-item pt-3">
                                <div className="item-sm-title">
                                    EMAIL
                                </div>
                                <div className="item-sm-value">
                                    contact@easymarket.com
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-send">
                            <div className="send-message-card">
                                <div className="card-title pt-3 pb-5">
                                    Envoyer un message
                                </div>
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="custom-input-wrapper">
                                                <label htmlFor="email" className="card-label">
                                                    Entrez votre adresse mail
                                                </label>
                                                <input 
                                                    type="text"
                                                    className="card-input" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pt-4">
                                            <div className="custom-input-wrapper">
                                                <label htmlFor="email" className="card-label">
                                                    Votre message
                                                </label>
                                                <textarea 
                                                    className="card-input" 
                                                    rows={8}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-container d-flex justify-content-center pt-5 pb-3">
                                        <button 
                                            type="submit"
                                            className="submit-btn"
                                        >
                                            Envoyer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="maps-container">
                    <iframe 
                        className="maps-frame"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.4682214196177!2d-17.52284792576366!3d14.742630873585286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec112dc3b6aa291%3A0xd7aeda2a6941677!2sYaas%20Hotel%20Dakar%20Almadies!5e0!3m2!1sfr!2ssn!4v1695659353590!5m2!1sfr!2ssn"  
                        height="500" 
                        style={{border:0}} 
                        allowFullScreen={true}
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
            <FrontFooter />
        </div>
    )
}

export default Contact