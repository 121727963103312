import React,{ useState,useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import CommandeDetailTable from "./tables/CommandeDetailsTable";
import { useLazyOrderBySlugQuery } from "../../../../utils/api/order/order.api";
import { useAppSelector } from "../../../../redux/hook";
import { formatDateHour } from "../../../../utils/Utils";

function CommandeDetail() {
    const navigate = useNavigate()

    const { slug } = useParams<{ slug: string }>();

    const [findBySlug, { data, isError ,isLoading }] = useLazyOrderBySlugQuery();
    
    const { user } = useAppSelector((s) => s?.user);
  
    useEffect(() => {
        if (slug) {
        findBySlug(slug as string);
        }
    }, [slug]);
  


    return(
        <div className="custom-content">
            <div className="action-btn-container">
                <button
                    className="go-back-btn"
                    onClick={() => navigate(-1)}
                >
                    <MdKeyboardBackspace />
                    Retour
                </button>
            </div>
            <div className="content-detail-commande-page detail-page mt-3 mb-3">
                <div className="table-content">
                    <CommandeDetailTable articles={data?.produits} isLoading={isLoading}/>
                </div>
                <div className="separator"></div>
                <div className="payment-mode-content pt-4">
                    <div className="row row-payment-mode">
                        <div className="col-md-7">
                            <div className="payment pb-4">
                                <div className="sm-title pb-2">Mode de paiement</div>
                                <div className="sm-value">
                                    {data?.mode_de_paiement}
                                </div>
                            </div>
                            <div className="separator"></div>
                            <div className="payment pt-4 pb-4">
                                <div className="sm-title pb-2">Statut de la commande</div>
                                <div className="sm-value">
                                    {data?.statut}
                                </div>
                            </div>
                            <div className="separator"></div>
                        </div>
                        <div className="col-md-5">
                            <div className="price-and-total-content">
                                <div className="subprice d-flex justify-content-between align-items-center pb-3">
                                    <span className="sm-value">
                                        Prix sous-total
                                    </span>
                                    <span className="sm-title">
                                        {data?.montant_total} FCFA
                                    </span>
                                </div>
                                <div className="fraissup d-flex justify-content-between align-items-center pb-3">
                                    <span className="sm-value">
                                        Frais de livraison (+) {" "} :
                                    </span>
                                    <span className="sm-title">
                                        0 FCFA
                                    </span>
                                </div>
                                <div className="maintotal d-flex justify-content-between align-items-center">
                                    <span className="sm-value">
                                        Total {" "}:
                                    </span>
                                    <span className="sm-title total">
                                        {data?.montant_total} FCFA
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommandeDetail