import React from "react";
import "./FrontFooter.css";
import Logo from "../../../assets/appImages/logo-footer.svg";
import { NavLink ,Link } from "react-router-dom";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useGetAllCategoryListQuery } from "../../../utils/api/category/category.api";
import { FiPhoneCall } from "react-icons/fi";
import { useGetProductListQuery} from "../../../utils/api/product/product.api";
import { usePagination, useSearch } from "../../../utils/hooks";



const FrontFooter = () => {
  const { limit, page, onChange } = usePagination(10);
  const { data , refetch } = useGetAllCategoryListQuery();
  
  return (
    <footer className="footer-component">
      <div className="container-easy-market">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 footer-grid">
            <div className="content-logo-app-footer pb-5">
              <img src={Logo} alt="logo" className="logo-app-footer" />
            </div>
            <p className="link-footer-title">
              Contactez-nous
            </p>
            <div className="ontact-us-container-footer">
              <div className="footer-contact-us-item mb-3">
                <span style={{ color: "#fff", paddingTop: 5 }}>
                  <AiOutlineWhatsApp style={{ fontSize: 18 }} />
                </span>
                <p className="link-footer mb-0 pb-0">
                  WhatsApp <br /> +221 77 171 48 18
                </p>
              </div>
              <div className="footer-contact-us-item">
                <span style={{ color: "#fff", paddingTop: 5 }}>
                  <FiPhoneCall style={{ fontSize: 18 }} />
                </span>
                <p className="link-footer pb-0 mb-0">
                  Téléphone <br /> +221 77 171 48 18
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4 col-lg-4 col-md-6 footer-grid">
            <p className="link-footer-title title-footer-deco pb-4">Nos catégories</p>
            {data?.results?.map((item, i) => (
            <Link key={item?.id} state={{ catgoryId: item?.id }} className="link-footer link-footer-deco trans-0-2 d-block" to="/produits">
              {item?.nom}
            </Link>
            ))}
          </div>
          <div className="mb-4 col-lg-4 col-md-6 footer-grid">
            <p className="link-footer title-footer-deco pb-4">Assistance</p>
              <NavLink
                className="link-footer link-footer-deco trans-0-2 d-block"
                to="/a-propos-de-nous"
              >
                Qui sommes nous ?
              </NavLink>
             <NavLink
                className="link-footer link-footer-deco trans-0-2 d-block"
                to="/contact"
              >
                Contactez-nous
              </NavLink>
               <NavLink
                className="link-footer link-footer-deco trans-0-2 d-block"
                to="/politique-de-confidentialite"
              >
                Politique de confidentialité
              </NavLink>
           
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
