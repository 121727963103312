import React, { useEffect } from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import { FaCubes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Commande from "../../../assets/appImages/commande.png";
import { useAppSelector } from "../../../redux/hook";
import { useOrderFromLocation, useSupportOrderMutation } from "../../../utils/api/order/order.api";
import { Color } from "../../../utils/theme";
import { formatCurrency, getImage, isAdmin, isPreparateur } from "../../../utils/Utils";

export const orderType = [
  {
    value: "livré",
    class: "statut-livre",
  },
  {
    value: "en cours de préparation",
    class: "statut-en-cours-de-prepation",
  },
  {
    value: "annulée",
    class: "statut-en-cours-de-prepation",
  },
  {
    value: "nouvelle",
    class: "statut-nouvelle",
  },
  {
    value: "en attente",
    class: "statut-nouvelle",
  },
  {
    value: "prête pour livraison",
    class: "statut-prete-pour-livraison",
  },
];
const CommandeItem = () => {
  const { user } = useAppSelector((s) => s?.user);
  const [item] = useOrderFromLocation();
  const navigate = useNavigate();
  const [getSupport, {isLoading,isSuccess,isError,error}] = useSupportOrderMutation()

   useEffect(() => {
     if (isSuccess) {
       Swal.fire({
         icon: "success",
         iconColor: Color.success,
         confirmButtonColor: Color.success,
         title: "La commande a été mise entre vos mains avec succès !",
         showConfirmButton: false,
         timer: 3000,
       })
     }
     const err = error as any;

     if (isError) {
       console.log("error", err);

       Swal.fire({
         icon: "error",
         title: err?.data?.message
           ? err?.data?.message
           : `Une erreur de statut ${err?.status} est survenue`,
         showConfirmButton: false,
         timer: 5000,
       });
     }
   }, [error, isError, isSuccess, isLoading]);
  return (
    <div className="container-commande-item">
      {/* <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
        <BsArrowLeft style={{ color: "#E20613" }} />
      </span> */}
      <div className="d-flex justify-content-between">
        <div
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
          className="title-admin"
        >
          <BsArrowLeft style={{ color: "#E20613" }} />
          <span className="ps-2">N°:
          {/* {item?.numOrder} */}
          </span>
        </div>
        {!item?.user && isPreparateur(user) && (
          <button
            className="btn btn-add-admin"
            onClick={() => getSupport(item?.slug)}
            style={{ border: "1px solid" }}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>En cours...</span>
              </>
            ) : (
              <>
                <FaCubes />
                <span className="ps-2">Prendre en charge</span>
              </>
            )}
          </button>
        )}
      </div>

      <div className="content-graph-admin my-3">
        <div className="container-info-commande-detail mb-4">
          {item?.produits?.map((product, i) => (
            <div
              className="row row-detail-commande-admin"
              key={product?.id}
            >
              <div className="col-md-6 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">Produit</h3>
                  )}

                  <div className="content-infdetail-commande-admin">
                    <div className="content-img-commande-admin">
                      <img
                        src={getImage("/")}
                        alt={product?.nom}
                        className="img-commande-detail-admin"
                      />
                    </div>
                    <p className="nom-commande-detail-admin mb-0">
                      {product?.nom}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">
                      Quantité
                    </h3>
                  )}

                  <p className="nom-commande-detail-admin mb-0 pt-5">
                    {/* {ordItem?.quantity} */}
                  </p>
                </div>
              </div>
              <div className="col-md-2 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">Prix</h3>
                  )}
                  <p className="nom-commande-detail-admin mb-0 pt-5">
                    {formatCurrency(product?.prix)}
                  </p>
                </div>
              </div>
              <div className="col-md-2 mb-3 col-detail-commande-admin">
                <div className="content-col-detail-commande-admin">
                  {i === 0 && (
                    <h3 className="title-col-detail-commande-admin">Total</h3>
                  )}
                  <p className="info-produit-sim-value mb-0 pt-5">
                    {/* {ordItem?.product?.price * ordItem?.quantity}{" "} */}
                    <span style={{ fontWeight: 300 }}>FCFA</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-lg-8 dis-flex itm-center">
              <div className="row w-full m-b-30">
                <div className="col-lg-8 col-md-10">
                  {/* <p className="resume-item fw-300 fs-14">
                          Date de livraison
                        </p>
                        <p className="resume-item fw-600 fs-14 ">
                          Livré entre jeudi 3 sept. et vendredi 4 sept.
                        </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <p className="resume-item fw-400 fs-14 m-b-20">
                Sous total :{" "}
                <span className="value-resume-item fs-14 fw-700">
                  {/* {formatCurrency(item?.subTotal)} */}
                </span>
              </p>
              <p className="resume-item fw-400 fs-14 m-b-20">
                Frais de livraison (+) :{" "}
                <span className="value-resume-item fs-14 fw-700">
                  {/* {formatCurrency(item?.livraison)} */}
                </span>
              </p>
              <p className="resume-item fw-400 fs-14 m-b-20">
                Tva (+) :{" "}
                <span className="value-resume-item fs-14 fw-700">
                  {/* {formatCurrency(item?.tva)} */}
                </span>
              </p>
              <div className="resume-item fw-700 itm-center m-b-20 justify-content-between">
                Total Commande :{" "}
                <p className="value-resume-item-ttc info-produit-sim-value mb-0">
                  {/* {item?.totalAmount}{" "} */}
                  <span style={{ fontWeight: 300 }}>FCFA</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-info-commande-detail mb-4">
          <div className="content-col-detail-commande-admin">
            <h3 className="title-col-detail-commande-admin">Acheteur</h3>
            <p className="nom-commande-detail-admin-value mb-0">
              {item?.user?.prenom + " " + item?.user?.nom}
            </p>
          </div>
        </div>
        <div className="container-info-commande-detail mb-4">
          <div className="col-md-6 mb-3 col-detail-commande-admin">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin">Adresse</h3>
              <p className="nom-commande-detail-admin-value mb-0">
                {/* {item?.adresse?.adresse} */}
              </p>
            </div>
          </div>
        </div>
        <div className="container-info-commande-detail mb-4">
          <div className="content-col-detail-commande-admin">
            <h3 className="title-col-detail-commande-admin">
              Mode de paiement
            </h3>
            <p className="nom-commande-detail-admin-value mb-0">
              {/* {item?.paymentMethod?.name} */}
            </p>
          </div>
        </div>
        <div className="container-info-commande-detail mb-4">
          <div className="content-col-detail-commande-admin">
            <h3 className="title-col-detail-commande-admin">
              Mode de livraison
            </h3>
            <p className="nom-commande-detail-admin-value mb-0">
              {/* {item?.modeLivraison?.name} */}
            </p>
          </div>
        </div>
        <div className="container-info-commande-detail mb-4">
          <div className="content-col-detail-commande-admin">
            <h3 className="title-col-detail-commande-admin">
              Statut de la commande
            </h3>
            <div className="container-checkbox-commande-statut mt-4">
              <div
                className={`${
                  orderType?.find((el) => el?.value === item?.statut)?.class
                } statut-commande`}
              >
                {item?.statut}
              </div>
            </div>
          </div>
        </div>
        {!!item?.user && isAdmin(user) && (
          <div className="container-info-commande-detail mb-4">
            <div className="content-col-detail-commande-admin">
              <h3 className="title-col-detail-commande-admin">Préparée par</h3>
              <p className="nom-commande-detail-admin-value mb-0">
                {item?.user?.prenom + " " + item?.user?.nom}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommandeItem;
