import React from "react";
import UseRegisterForm from "../requestForm/UseRegisterForm";
import { FaUser } from "react-icons/fa";
import ErrorMessage from "../../common/ErrorMessage";
import { RiLockFill } from "react-icons/ri";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { MdMail, MdLocalPhone } from "react-icons/md";
import PhoneInput from "react-phone-input-2";

function RegisterTab({closeModalConnexion}) {

  const {
    onSubmit,
    register,
    isLoading,
    errors,
    handleChangePhone,
    phone,
  } = UseRegisterForm(closeModalConnexion);
  const [showPassword, setShowPassword] = React.useState(false);

    return (
        <>
            <form id="auth-form" onSubmit={onSubmit}>
                <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <FaUser />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="recipient_firstname"
                          placeholder="Prenom"
                          aria-label="Prenom"
                          {...register("firstName")}
                        />
                        <ErrorMessage
                          message={errors?.firstName && errors?.firstName?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <FaUser />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="recipient_lastname"
                          placeholder="Nom"
                          aria-label="Nom"
                          {...register("lastName")}
                        />
                        <ErrorMessage
                          message={errors?.lastName && errors?.lastName?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <MdMail />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Email"
                          aria-label="Email"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <MdLocalPhone />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Telephone"
                          aria-label="Telephone"
                          {...register("phone")}
                        />
                        {/* <PhoneInput
                          inputProps={{
                            name: "phone",
                          }}
                          defaultMask={".. ... .. .."}
                          country="sn"
                          value={phone}
                          enableSearch={true}
                          onChange={(phone, country) =>
                            handleChangePhone(phone, country)
                          }
                          inputClass="form-control auth-form-control"
                          enableAreaCodes={true}
                          prefix="+"
                          countryCodeEditable={false}
                        /> */}
                        
                        <ErrorMessage
                          message={errors?.phone && errors?.phone?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-2">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <RiLockFill />
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control auth-form-control"
                          id="password"
                          placeholder="Mot de passe"
                          aria-label="Password"
                          {...register("password")}
                        />
                        <span
                          className="show-hide-password"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        message={errors?.password && errors?.password?.message}
                      />
                    </div>
                    <div className="col-md-12 auth-input-col mb-2">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <RiLockFill />
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control auth-form-control"
                          id="confirmation_password"
                          placeholder="Confirmation mot de passe"
                          aria-label="Confirmation Password"
                          {...register("confirmPassword")}
                        />
                        <span
                          className="show-hide-password"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        message={errors?.confirmPassword && errors?.confirmPassword?.message}
                      />
                    </div>
                    <div className="col-md-12 d-flex justify-content-center pt-5">
                        <button
                          // to="/admin/dashboard"
                          className="btn auth-submit-btn trans-0-2"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Inscription...</span>
                            </>
                          ) : (
                            "Inscription"
                          )}
                        </button>
                    </div>
                </div>
                <div className="accept_or_decline_wrapper py-5">
                  <div className="form-check custom_check__content d-flex align-items-center gap-2 p-0">
                    <input 
                      className="form-check-input m-0 " 
                      type="checkbox" 
                      value="" 
                      id="remember__me" 
                    />
                    <label className="form-check-label" htmlFor="remember__me">
                      J’accepte les&nbsp;
                      <NavLink
                        to={`//mentions-legales`}
                        className="redirect_page_link"
                      >conditions d’utilisations</NavLink>&nbsp;
                      et la&nbsp;
                      <NavLink
                        to={`/politique-de-confidentialite`}
                        className="redirect_page_link"
                      >politique de confidentialité</NavLink>
                    </label>
                  </div>
                </div>
            </form>
        </>
    )
}

export default RegisterTab;