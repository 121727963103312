import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AuthState } from "../user/user.type";
import {
  CodeConfirmation,
  ForgetPasswordFormData,
  LoginFormData,
  LoginResult,
  RegisterFormData,
  ResendCode,
  ResetPasswordFormData,
} from "./auth.type";

export const AuthApi = createApi({
  reducerPath: "auth",
  tagTypes: ["auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
  }),
  endpoints: (build) => ({
    // register user mutation
    registerUser: build.mutation<AuthState["user"],RegisterFormData | FormData>({
      query: (data) => ({
        url: "/acheteur/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      transformResponse: ({ data }) => data,
    }),
    // login user mutation
    loginUser: build.mutation<LoginResult, LoginFormData>({
      query: (data) => ({
        url: "/login/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    // request to reset user password mutation
    forgetPassword: build.mutation<any, ForgetPasswordFormData>({
      query: (data) => ({
        url: `auth/forgot-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    //reset user password mutation
    resetUserPassword: build.mutation<any, ResetPasswordFormData>({
      query: (data) => ({
        url: `auth/reset-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    // Confirmation code otp
    confirmPhoneOtp: build.mutation<any, CodeConfirmation>({
      query: (data) => ({
        url: `auth/confirm-phone-number/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    resendCode: build.mutation<any, ResendCode>({
      query: (data) => ({
        url: `auth/resend-phone-verification-code/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useForgetPasswordMutation,
  useResetUserPasswordMutation,
  useConfirmPhoneOtpMutation,
  useResendCodeMutation
} = AuthApi;
