import React ,{useState,useEffect} from "react";
import { NavLink } from "react-router-dom";
// import CommandeImg from "../../../assets/appImages/commande.png";
import StarRatingComponent from "react-star-rating-component";
import { BsTrash } from "react-icons/bs";
import Panier from "../../../assets/appImages/panier.png";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  calculateTax,
  CartItemType,
  decrement,
  getCartCount,
  //getSubTotal,
  getTotalAmount,
  increment,
  removeCartItem,
} from "../../../../redux/slice/useCartSlice";
import { formatCurrency, getImage } from "../../../../utils/Utils";
import PanierItemTable from "./PanierItemTable";
import { AppStorage } from "../../../../utils/storage";

const PanierPage = () => {
  const { panier, user, token } = useAppSelector((s) => s?.user);

  const { cartItems, subAmount, tax, totalAmount } = useAppSelector(
    (state) => state.cart
  );
  const [prduits, setPrduits] = React.useState<any>([]);
  const [total, setTotal] = React.useState<any>(0);
  // const [count, setCount] = React.useState(1);

 // let paniers = JSON.parse(AppStorage.getItem('panier'));

  

  useEffect(()=>{

    let somme = 0 

    panier.forEach(element => {
      somme = somme + (element?.prix * element?.quantite)
    });

    setTotal(somme)

  },[panier]);



  return (

    <>
        <div className="content-title-user-parcours pb-5">
            <h3 className="user-parcours-title">Mon panier</h3>
        </div>
        <div className="row">
            <div className="col-md-9">
              <div className="cart-items-table-content">
                <PanierItemTable produits={panier}/>
              </div>
            </div>
            <div className="col-md-3 cart-total">
                <div className="cart-total-card">
                    <div className="sm-title pb-4">Total du panier</div>
                    {/* <div className="sm-value d-flex justify-content-between align-items-center pb-2">
                        <span>Sous-total</span>
                        <span>1000</span>
                    </div> */}
                    <div className="separator"></div>
                    <div className="sm-value d-flex justify-content-between align-items-center pt-2">
                        <span>TOTAL</span>
                        <span>{total} FCFA</span>
                    </div>
                    <div className="btn-container pt-5">
                        <NavLink to="/valider-commande" className="btn parcours-submit-btn">
                            Valider mon panier
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default PanierPage;

export function Counter({
  count,
  setCount,
}: {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}) {
  const decrement = () => {
    setCount((prevState) => prevState - 1);
  };

  const increment = () => {
    setCount((prevState) => prevState + 1);
  };

  return (
    <div className="btn-wrap">
      <button
        className="btn decrement-btn btn-counter-item"
        disabled={count <= 1}
        onClick={decrement}
      >
        −
      </button>
      <div className="num">{count}</div>
      <button className="btn increment-btn btn-counter-item" onClick={increment}>
        +
      </button>
    </div>
  );
}

const CartItem = ({ item }: { item: CartItemType }) => {
  const [count, setCount] = React.useState(item?.qty);
  const dispatch = useAppDispatch();

  const getCartItems = () => {
   // dispatch(getSubTotal());
    dispatch(getCartCount());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
  };
  React.useEffect(() => {
    if (count > item?.qty) {
      console.log("inc", count, item?.qty);
      dispatch(increment(item));
      getCartItems();
    }

    if (count < item?.qty) {
      console.log("dec", count, item?.qty);
      dispatch(decrement(item));
      getCartItems();
    }
  }, [count]);

  const onRemoveItem = () => {
    dispatch(removeCartItem(item?.product));
    getCartItems();
  };
  return (
    <div className="detail-commande-item card-panier-detail mb-1">
      <div className="content-info-commande-item w-50">
        <div className="content-img-commande-item">
          <NavLink
            to={`/produit/${item?.product?.id}`}
            state={item?.product}
            className="no-link "
          >
            <img
              src={getImage('/')}
              alt="Commande"
              className="img-commande-item"
            />
          </NavLink>
        </div>
        <div className="info-commande-item">
          <p className="text-name-commande-item mb-1">{item?.product?.nom}</p>
          <div className="text-muted" style={{ fontSize: "0.80rem" }}>
            {formatCurrency(item?.product?.prix)}
          </div>
          <div className="content-rate-product">
            <StarRatingComponent
              name="rate2"
              starCount={5}
              value={item?.product?.prix}
              starColor="#DD9812"
              emptyStarColor="#D4D6D5"
            />
          </div>
        </div>
      </div>
      <div className="content-qty-items w-15">
        <div className="counter ">
          <Counter setCount={setCount} count={count} />
        </div>
      </div>
      <div className="content-detail-commande-panier">
        <p className="text-price-commande-item">
          {(item?.product?.prix) * item?.qty}{" "}
          <span style={{ fontWeight: 400 }}>FCFA</span>
        </p>
        <div className="content-btn-panier-page mb-3">
          <button className="btn" onClick={() => onRemoveItem()}>
            <BsTrash style={{ color: "#E20613", fontSize: 20 }} />
          </button>
        </div>
      </div>
    </div>
  );
};
