import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../utils/api/user/user.type";
import { AppStorage } from "../../utils/storage";

const initialState = AppStorage.getItem<AuthState>("user", {
  user: undefined,
  token: undefined,
  panier: undefined ,
});
export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onSetUser: (state, action: PayloadAction<AuthState["user"]>) => {
      state.user = action.payload;
      AppStorage.setItem("user", state);
    },
    onSetToken: (state, action: PayloadAction<AuthState["token"]>) => {
      state.token = action.payload;
      AppStorage.setItem("token", state.token);
    },
    onSetUserToken: (state, action: PayloadAction<AuthState>) => {
      console.log("user sate",action)
      state.user = action.payload.user?.data;
      state.token = action.payload.token;
      AppStorage.setItem("user", state);
    },
    onSetPanier: (state, action: PayloadAction<AuthState["panier"]>) => {
      state.panier = action.payload;
      console.log('state.panier',state.panier)
      AppStorage.setItem('panier', JSON.stringify( state.panier));
    //  AppStorage.setItem("panier", state);
    },
    onlogout: (state) => {
      state.user = null;
      state.token = null;
      AppStorage.setItem("user", state);
      AppStorage.clear();
    },
  },
});

export const { onSetUser, onSetUserToken, onSetToken, onSetPanier, onlogout } =
  UserSlice.actions;
export default UserSlice.reducer;
