import React, { useState , useEffect} from "react";
import { NavLink } from "react-router-dom";
import "./FrontHeader.css";
import Logo from "../../../assets/appImages/logo.svg";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FiShoppingCart, FiUser } from "react-icons/fi";
import ConnexionModal from "./modal/ConnexionModal";
import Car from "../../../assets/icons/car.svg"
import CategoriesList from "./CategoriesList";
import SearchForm from "./SearchForm";
import { IoCallOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { AppStorage } from "../../../utils/storage";
import { onSetPanier } from "../../../redux/slice/User.slice";


const FrontHeader = () => {
  const dispatch = useAppDispatch();

  const { panier, user, token } = useAppSelector((s) => s?.user);
  const [modalConnexion, setModalConnexion] = useState(false);

  console.log('panier',panier)

  useEffect(()=>{
    let panier = JSON.parse(AppStorage.getItem('panier'));

   
    if(!panier)
    {
      var panierArray:any = [];
     // AppStorage.setItem('panier', JSON.stringify(panierArray));
      dispatch(onSetPanier(panierArray));

    }

   dispatch(onSetPanier(panier));

  },[]);

  console.log('panier',panier)


  return (
    <div className="frontheader-component headering fixed-top">
      <div className="second-header-custom">
        <div className="content-left-second-header">
            <img src={Car} alt="Service" className="icon-svg-img" />
            <p className="text-second-header-custom">
              Service disponible 24h/24
            </p>
        </div>
        <div className="content-left-second-header">
            <span>
              <IoCallOutline />
            </span>
            <p className="text-second-header-custom">
              Livraison en 24h
            </p>
        </div>
        <div className="content-left-second-header">
            <img src={Car} alt="Service" className="icon-svg-img" />
            <p className="text-second-header-custom">
              33 00 000 000
            </p>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light firstheader-costum nav-header-custom">
        <div className="container-fluid header-container-fluid pb-0">
          <div className="logo-and-version-container">
              <div className="logo-container">
                <NavLink className="navbar-brand content-logo-app" to="/">
                  <img src={Logo} alt="EasyMarket" className="app-logo" />
                </NavLink>
              </div>
          </div>
          <button
            className="navbar-toggler content-icon-menu-mobile"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse header-navbar-collapse"
            id="navbarNav"
          >
            <div className="w-100 header-items-container">
              <div className="w-100 header-navbar-contents-container">
                <div className="bloc-flex-info-user-header-content">
                  <div className="bloc-flex-info-user-header">
                    <div className="search-form-container">
                      <SearchForm />
                    </div>
                    <div className="categorie-btn-container no-view-mobile">
                      <CategoriesList />
                    </div>
                  </div>
                </div>
                <ul className="navbar-nav navbar-nav-header">
                  <li className="nav-item">
                    {!token ?
                      <button
                      onClick={() => setModalConnexion(true)}
                      className="btn nav-link menu-link"
                      >
                      <FiUser />
                      <span className="ps-2">Se Connecter</span>
                      </button>
                    :
                      <NavLink
                        to={`/mon-compte/profil`}
                        className="btn nav-link menu-link"
                      >
                        <FiUser />
                        <span className="ps-2">Mon compte</span>
                      </NavLink>
                    }
                      
                    <ConnexionModal
                      modalConnexion={modalConnexion}
                      setModalConnexion={setModalConnexion}
                    />
                  </li>
                  <li className="nav-item">
                      <button
                        className="btn nav-link menu-link"
                      >
                        <AiOutlineHeart />
                        <span className="ps-2">Mes favoris</span>
                      </button>
                  </li>
                  <li className="nav-item no-view-mobile">
                      <NavLink
                        to={`/panier`}
                        className="btn nav-link menu-link position-relative"
                      >
                        <FiShoppingCart style={{ fontSize: 15 }} />
                        <span className="ps-2">Panier</span>
                        <span className="counter-shop-notif">{ panier?.length }</span>
                      </NavLink>
                  </li>
                </ul>
                <div className="content-nav-mobile-category no-view-desktop">
                  <div className="navbar-nav">
                    <li className="nav-item">
                      <NavLink
                        className="btn nav-link menu-link position-relative"
                        to="/mon-compte/panier"
                      >
                        <AiOutlineShoppingCart />
                        <span className="counter-shop-notif">2</span>
                      </NavLink>
                    </li>
                  </div>
                  <div className="categorie-btn-container">
                    <CategoriesList />
                  </div>
                </div>
                {/* <ShoppingCart isOpen={isOpen} setIsOpen={setIsOpen} /> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
      
    </div>
  );
};

export default FrontHeader;
