import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import PhoneInput from "react-phone-input-2";
import UseEditInfosForm from "../../../../admin/ProfilAdmin/requestForm/UseEditInfosForm";
import ErrorMessage from "../../../../common/ErrorMessage";
import { useAppSelector } from "../../../../../redux/hook";


const AdresseLivraison = ({ setIndex,register,errors,handleChangePhone, phone }) => {

  const { user } = useAppSelector((s) => s?.user);

  // const {
  //   register,
  //   handelImageChange,
  //   handleChangePhone,
  //   phone,
  //   onSubmit,
  //   isLoading,
  //   errors,
  //   user,
  // } = UseEditInfosForm();

  return (
    <div className="form-register-tabs-component mt-5">
      <p className="sub-title-form-register">Adresse de livraison</p>
      {/* <form action=""> */}
        <div className="infos_livraison_container pt-4">
            <div className="row">
                <div className="col-md-7 mb-3">
                    <div className="input_wrapper position-relative">
                      <span className="icon_wrapper">
                          <AiOutlineUser size={22} />
                      </span>
                      <input
                        className="form-control form-control-register-tabs"
                        type="text"
                        placeholder="Adjaratou Ndiaye"
                        defaultValue={user?.prenom+' '+user?.nom}
                      />
                      <ErrorMessage
                        message={errors?.nom && errors?.nom?.message}
                      />
                    </div>
                </div>
                <div className="col-md-7 mb-3">
                  <div className="input_wrapper">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                        }}
                        defaultMask={".. ... .. .."}
                        country="sn"
                        value={phone}
                        enableSearch={true}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        inputClass="form-control-register-tabs-tel"
                        enableAreaCodes={true}
                        prefix="+"
                        countryCodeEditable={false}
                      />      
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="input_wrapper position-relative">
                      <span className="icon_wrapper">
                        <HiOutlineLocationMarker size={22} />
                      </span>
                      <input
                        className="form-control form-control-register-tabs"
                        type="text"
                        placeholder="Ouest Foire"
                        {...register("adresse")}
                        defaultValue={user?.adresse}
                      />
                      <ErrorMessage
                        message={errors?.adresse && errors?.adresse?.message}
                      /> 
                  </div>
                </div>
            </div>
        </div>
      {/* </form> */}
    </div>
  );
};

export default AdresseLivraison;
