import { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "./theme";

export function useDelete<T>({
	item,
	deleteItem,
	isSuccess,
	isError,
	error,
	question,
	successMessage,
}: {
	item: T | any;
	deleteItem: (slug: string) => void;
	isSuccess: boolean;
	isError: boolean;
	error: any;
	question: string;
	successMessage: string;
}) {
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes vous sûr de vouloir supprimer ${question}`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.success,
			preConfirm: () => {
				return deleteItem(item.id);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${successMessage} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}
