import React from "react";
import { ProductData, ProductItem } from "../../HomePage/HomePage";
import { useGetProductListQuery} from "../../../utils/api/product/product.api";
import { usePagination, useSearch } from "../../../utils/hooks";


function ProductSimilary({category}) {
  const { limit, page, onChange } = usePagination(10);
  const { data , refetch } = useGetProductListQuery({
    page: page,
    limit: limit
  });

  return (
    <div className="container-produits-similaires mb-5">
      <h3 className="title-produits-similaires">Produits similaires</h3>
      <div className="row py-4">
        {/* {!!isLoading && <ProductSimilarySkeleton />} */}

        {data?.results?.map((produit,i) => (
          <div
            className="col-lg-3 col-md-6 col-produits-similaires mb-4 pb-4"
            key={i}
          >
            <ProductItem item={produit} key={i} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductSimilary;
