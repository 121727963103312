import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ConfirmTransactionFormData, DeliveryAmount, DeliveryAmountFormData, Order, OrderFormData } from "./order.type";

export const OrderApi = createApi({
  reducerPath: "orderApi",
  tagTypes: [
    "order",
    "orderList",
    "orderByVisitor",
    "orderById",
    "allOrderList",
    "orderByAcheteur",
  
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListOrderAdmin: builder.query<PaginationResults<Order>, TypeQuery>({
      providesTags: ["orderList"],
      query: (query) => QueryUrl("orders/", query),
    }),
    getOrderList: builder.query<PaginationResults<Order>, TypeQuery>({
      providesTags: ["order"],
      query: (query) => QueryUrl("commandes/me/", query),
    }),
    getListOrder: builder.query<any | Order[], void>({
      providesTags: ["orderList"],
      query: () => {
        return "orders/";
      },
    }),
    addOrEditOrder: builder.mutation<Order,{ slug?: string; data: OrderFormData | FormData }>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            OrderApi.util.invalidateTags([
              "orderList",
              "orderByVisitor",
              "order",
            ])
          );
        }, 1000);
      },
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `orders/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `orders/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteOrder: builder.mutation<OrderFormData, string>({
      query: (slug) => ({
        url: `orders/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["orderList", "orderByVisitor", "order"],
    }),
    OrderBySlug: builder.query<Order | any, string>({
      query: (slug) => `orders/${slug}/`,
      providesTags: ["orderById"],
    }),
    OrderByAcheteur: builder.query<Order | any, string>({
      query: (slug) => `orders/acheteur/${slug}/`,
      providesTags: ["orderByAcheteur"],
    }),
    OrderByTransactionID: builder.query<Order | any, string>({
      query: (slug) => `orders/order-by-transaction/${slug}/`,
      providesTags: ["orderById"],
    }),
    supportOrder: builder.mutation<Order, string>({
      query: (slug) => ({
        url: `order/support/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["order", "orderById", "orderList","allOrderList"],
    }),
    getAmountDelivery: builder.mutation<DeliveryAmount, DeliveryAmountFormData>(
      {
        query: (data) => ({
          url: `commandes/delivary-fees/`,
          method: "POST",
          body: data,
        }),
      }
    ),
    confirmTransaction: builder.mutation<any, ConfirmTransactionFormData>({
      query: (data) => ({
        url: `order/kpay/confirm-payment/`,
        method: "POST",
        body: data,
      }),
    }),
    codeByTransactionID: builder.query<Order | any, string>({
      query: (slug) => `order/kpay/resend-otp/${slug}/`,
      // providesTags: ["orderById"],
    }),
  }),
});

export const {
  useAddOrEditOrderMutation,
  useGetListOrderAdminQuery,
  useGetListOrderQuery,
  useLazyOrderBySlugQuery,
  useLazyOrderByAcheteurQuery,
  useGetOrderListQuery,
  useSupportOrderMutation,
  useLazyOrderByTransactionIDQuery,
  useGetAmountDeliveryMutation,
  useConfirmTransactionMutation,
  useLazyCodeByTransactionIDQuery,
  useDeleteOrderMutation
} = OrderApi;

export function useOrderFromLocation(): [
  Order,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Order | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyOrderBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Order, isLoading, id as string, findById];
}
