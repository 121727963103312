import React, {useEffect,useState} from 'react'
import { AlertInfo } from '../../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from '../../../../common/TableSkeleton';
import { FaTrash } from "react-icons/fa";
import { AiFillEye } from 'react-icons/ai';
import { NavLink, useParams } from 'react-router-dom';
import product from "../../../../../assets/appImages/product_image.png";
import { useAppSelector } from "../../../../../redux/hook";
import { getImage } from "../../../../../utils/Utils";


function CommandeDetailTable({articles, isLoading}) {

  const [pages, setPages] = useState(CommandeDetailTableData)

    const actionFormatter = (cell: any, row: any) => {
      return (
        <>
          <div className="table-actions-btn-container-commandes d-flex justify-content-center align-items-center gap-3">
            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/mon-compte/commandes/${row.slug}`}
                className="btn btn-action-modal-icon with-tooltip"
                data-tooltip-content="Voir"
                style={{ color: "#158A91" }}
              >
                <AiFillEye />
              </NavLink>
            </div>
            <div className="container-btn-action-icon-modal">
                <button
                    className="btn with-tooltip btn-action-icon-delete"
                    data-tooltip-content="Supprimer"
                    style={{ color: "#E53E3E" }}
                >
                    <FaTrash />
                </button>
            </div>
          </div>
        </>
      );
    };

    const productFormatter = (cell: any, row: any) => {
      return(
        <div className="d-flex align-items-center gap-2">
            <span className="product-image-container">
                <img 
                    src={getImage(row?.variations[0]?.images[0]?.image) } 
                    alt="image"
                    className="image" 
                />
            </span>
            <span className="product-name">
                {row?.produit?.nom}
            </span>
        </div>
      )
    }

    const totalFormatter = (cell: any, row: any) => {
      return row?.quantite * row?.prix
    }

    const columns = [
      {
        dataField: "nom",
        text: "Produits",
        formatter: productFormatter
      },
      {
        dataField: "quantite",
        text: "Quantité",
      },
      {
        dataField: "prix",
        text: "Prix",
      },
      {
        dataField: "total",
        text: "Total",
        formatter: totalFormatter
      },
    ];

    return (
      <>
      {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <BootstrapTable
              keyField="id"
              data={articles || []}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive commande-detail-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
          />
        )
        }
      </>
    );
}

export default CommandeDetailTable

const CommandeDetailTableData = [
    {
        id: 1,
        image: product,
        product_name: "Couches pampers",
        quantity: 1,
        price: 3000,
        total: "3000 fcfa",
    },
]

