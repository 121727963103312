import React , {ChangeEvent, useEffect,useState} from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import StarRatingComponent from "react-star-rating-component";
import { MdOutlineCallToAction } from "react-icons/md";
import { Counter } from "../../MonCompte/MonComptePage/Panier/PanierPage";
import { FaShoppingCart } from "react-icons/fa";
import { AiOutlineHeart } from "react-icons/ai";
import GaleryProduct, { images } from "./GaleryProduct";
import Zoom from "react-reveal/Zoom";
import Roll from "react-reveal/Roll";
import AvisProduct from "./AvisProduct";
import ProductSimilary from "./ProductSimilary";
import { BiCube } from "react-icons/bi";
import { NavLink , useParams } from "react-router-dom";
import { useLazyProductBySlugQuery } from "../../../utils/api/product/product.api";
import {  useAppDispatch, useAppSelector } from "../../../redux/hook";
import { AppStorage } from "../../../utils/storage";
import { ProductColorData, ProductSizeData } from "./data";
import { Color } from "../../../utils/theme";
import Swal from "sweetalert2";
import { onSetPanier } from "../../../redux/slice/User.slice";


const ProduitItemPage = () => {
  // const [item] = useProductFromLocation();
  const dispatch = useAppDispatch();

  const [item] = images;

  const { slug } = useParams<{ slug: string }>();
  const [showProfil , setShowProfil] = useState(true);
  const [variationActive , setVariationActive] = useState<any>();
  const [imagesProduit , setImagesProduit] = useState<any>();
  const [tailles , setTailles] = useState<any>();
  const [findBySlug, { data, isError ,isLoading }] = useLazyProductBySlugQuery();
  
  const { user } = useAppSelector((s) => s?.user);

  useEffect(() => {
      if (slug) {
      findBySlug(slug as string);
      }
  }, [slug]);

  useEffect(() => {
    
    setVariationActive(data?.variations[0]);
    
}, [data]);

  useEffect(() => {
    setImagesProduit(variationActive?.images)
    setTailles(data?.variations?.filter((x) => (x.couleur == variationActive?.couleur)))
  }, [variationActive]);

  const navigate = useNavigate();
  const [count, setCount] = React.useState(1);
  // const [sendData, { isLoading, isSuccess, isError, error }] =
  //   useAddOrDeleteFavoriteMutation();
  // const onConfirm = AddedCartConform(item);

  
  const addToCart = () => {
    
    let  panierArray = JSON.parse(AppStorage.getItem('panier'));

    let produit = panierArray?.find(x => (x?.id == data?.id))

    if(!produit)
    {
      panierArray.push({ ...data, "quantite": count });
      //AppStorage.setItem('panier', JSON.stringify(panierArray));
      dispatch(onSetPanier(panierArray));
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Produit ajouté au panier avec succès !",
        showConfirmButton: false,
        timer: 3000,
      });
    }

  };

  const onSelectColor = (item) => {
    
    setVariationActive(item)

  };

  const onSelectSize = (item) => {
    
   console.log('item',item)

  };

  const ColorVariationItem = (
    {color, isActive , variation}:
    {color: string, isActive?: boolean , variation?: any}
    ) => {
      // const [currentColor, setCurrentColor] = React.useState<boolean>(false);
      // isActive = currentColor;
    return(
      <div className={ variationActive?.id == isActive ? 'btn_color_wrapper' : ''}>
        <label
          role="button"
          className={`btn btn_color_choose`}
          style={{ background: color }}
          onClick={() => onSelectColor(variation)}
        ></label>
          
            <input 
              type="radio" 
              name="variation_color" 
              id="variation_color" 
            />
        
      </div>
    )
  }

  const SizeVariationItem = (
    {size, isActive, variation}:
    {size: string, isActive: boolean , variation:any}
    ) => {
      // const [currentSize, setCurrentSize] = React.useState<boolean>(false);
      // isActive = currentSize

    return(
      <label
        role="button"
        className={variationActive?.id == isActive ? 'btn btn_size_choose active ' : 'btn btn_size_choose'}
        onClick={() => onSelectSize(variation)}
      >
        
          <input 
            type="radio" 
            name="variation_size" 
            id="variation_size" 
          />
        
        {size}
      </label>
    )
  }


  // const onToggleFavorite = async () => {
  //   if (!user) {
  //     navigate(`/?next=${location?.pathname}`)
  //   } else {
  //     await sendData({ product: item?._id });
  //   }
    
  // };

  // React.useEffect(() => {
  //   if (item?.favorite?.product === item?._id) {
  //     setIsFavorite(true);
  //   } else {
  //     setIsFavorite(false);
  //   }
  // }, [item]);

  // React.useEffect(() => {
  //   // window.scroll(0, 10);
  //   if (isSuccess) {
  //     Swal.fire({
  //       icon: "success",
  //       title: isFavorite
  //         ? `Ce produit a été supprimé de vos favories avec succès!`
  //         : `Ce produit a été ajouté à vos favories avec succès!`,
  //       iconColor: Color.success,
  //       showConfirmButton: false,
  //       timer: 3000,
  //     });
  //   }
  //   const err = error as any;

  //   if (isError) {
  //     console.log("err", err);
  //     Swal.fire({
  //       icon: "error",
  //       title: err?.data?.message
  //         ? err?.data?.message
  //         : `Une erreur de statut ${err?.status} est survenue lors de l'ajout`,
  //       showConfirmButton: false,
  //       timer: 5000,
  //     });
  //   }
  // }, [error, isError, isSuccess]);
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content bg-white pb-5">
        <div className="container-easy-market">
          <Zoom top cascade>
            <div
              className="content-back-to pb-5 pt-3"
              onClick={(e) => {
                e?.preventDefault();
                navigate(-1);
              }}
            >
              <HiOutlineArrowLeft style={{ fontSize: 18 }} />
              <span className="ps-2 go-back-btn">RETOUR</span>
            </div>
          </Zoom>
          <div className="row row-product-item-page mb-3">
            <div className="col-lg-6 col-md-12 col-left-product-item-page d-flex">
              <div className="content-col-left-product-item-page container-product-item-page">
                <GaleryProduct pictures={ imagesProduit ? imagesProduit : data?.variations[0]?.images} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-left-product-item-page d-flex">
              <div className="content-col-left-product-item-page container-product-item-page">
                 <Roll right cascade>
                  <p className="name-prduct mb-2">{data?.nom}</p>
                </Roll>
                <Roll right cascade>
                  <p className="category-product-name mb-1">
                   Categorie: Home
                  </p>
                </Roll>
               
                <div className="container-rate-product pb-3">
                  <Roll right cascade>
                    <div className="content-rate-product">
                      <StarRatingComponent
                        name="rate2"
                        starCount={5}
                        value={4}
                        starColor="#DDB012"
                        emptyStarColor="#D4D6D5"
                      />
                    </div>
                  </Roll>
                </div>

                <div className="custom_container container-product-description pb-4">
                  <Roll right cascade>
                    <p className="custom_sm__title">Description</p>
                  </Roll>
                   <Roll right cascade>
                    <p className="text-describe-product-item-detail py-3">
                      {data?.description}
                    </p>
                  </Roll>
                </div>
                
                <div className="custom_container container-product-price pb-4">
                  <Roll right cascade>
                    <p className="custom_sm__price pb-3">
                    {data?.articlePrice} FCFA
                    </p>
                  </Roll>
                </div>

                <div className="custom_container variation_content container-product-colors pb-4">
                  <Roll right cascade>
                    <p className="m-0 pb-2 custom_sm__title">Couleur</p>
                  </Roll>
                  <Roll right cascade>
                    <div className="d-flex align-items-center gap-3 avalaible_colors_wrapper">
                    {!!data?.variations &&
                       data?.variations.map((item, key) => (
                        <ColorVariationItem 
                          color={item.couleur}
                          isActive={item.id}
                          variation={item}
                          key={key}
                        />
                       ))
                    }
                    </div>
                  </Roll>
                </div>

                <div className="custom_container variation_content container-product-sizes pb-3">
                  <Roll right cascade>
                    <p className="m-0 pb-2 custom_sm__title">Taille</p>
                  </Roll>
                  <Roll right cascade>
                    <div className="d-flex align-items-center gap-3 avalaible_sizes_wrapper">
                    {!!tailles &&
                       tailles.map((item, key) => (
                        <SizeVariationItem 
                          size={item.taille}
                          isActive={item.id}
                          variation={item}
                          key={key}
                        />
                       ))
                    }
                    </div>
                  </Roll>
                </div>
                
                <div className="container-quantite-produit mb-4">
                  <p className="title-quantity-product">Quantité</p>
                  <div className="counter mt-3 mb-5">
                  <Counter setCount={setCount} count={count} />
                </div>
                </div>
                 <div className="container-quantite-produit">
                  <p className="title-quantity-product">Total</p>
                  <p className="title-quantity-product">{data?.prix * count} FCFA</p>
                </div>
                <div className="container-btn-add-to-cart-product mt-4">
                  <div className="content-btn-commande w-100">
                  <button
                    className="btn btn-theme-fav-product w-100"
                    // onClick={() => onToggleFavorite()}
                    // disabled={isLoading}
                  >
                    {/* {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1 d-inline-block"
                          role="status"
                        ></span>
                      </>
                    ) : ( */}
                    <span className="pe-2">Favoris </span>
                     <span> <AiOutlineHeart /></span>
                    {/* )}

                    <span className="ps-2">
                      {" "}
                      {isLoading
                        ? "AJOUT EN COURS..."
                        : isFavorite
                        ? "Supprimer de vos favoris"
                        : "AJOUTER AU favoris"}{" "}
                    </span> */}
                  </button>
                </div>
                <div className="content-btn-commande w-100">
                  <button
                    className="btn btn-theme-annim w-100"
                    onClick={() => addToCart()}
                  >
                    
                    <span className="pe-2">AJOUTER AU PANIER</span>
                    <FaShoppingCart />
                  </button>
                </div>
                </div>
                
              </div>
            </div>
          </div>
          <AvisProduct product={item} />
          <ProductSimilary category={data?.category?.id} />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ProduitItemPage;