import React from "react";
import Kpay from "../../../../../assets/icons/kpay.png";
import orange from "../../../../../assets/icons/orange-money.png";
import wave from "../../../../../assets/icons/wave.png";
import chart from "../../../../../assets/icons/chart.png";
import livreur from "../../../../../assets/icons/livreur.png";

const ModePaiement = ({ setIndex,register,errors }) => {


  return (
    <div className="form-register-tabs-component mt-5">
      <p className="sub-title-form-register">Choisir un mode de paiment</p>
      <div className="content-form-register-tabs mt-5">
          <div className="row-add mb-4">
              <div className="col-form-register-tabs mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="content-col-form-livraison position-relative cpntrol">
                      <label className="label-checkbox-register-tabs d-flex align-items-center">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          {...register("mode_de_paiement")}
                          value="WAVE_SN_API_CASH_OUT"
                        />
                        Wave 
                      </label>
                      <div className="icon-mode-paiement-position">
                        <img src={wave} alt="wave" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"><div></div></div>
                </div>
              </div>
              <div className="col-form-register-tabs mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="content-col-form-livraison position-relative cpntrol">
                      <label className="label-checkbox-register-tabs d-flex align-items-center">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          {...register("mode_de_paiement")}
                          value="ORANGE_SN_API_CASH_OUT"
                        />
                        Orange Money 
                      </label>
                      <div className="icon-mode-paiement-position">
                        <img src={orange} alt="orange" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"><div></div></div>
                </div>
              </div>
              <div className="col-form-register-tabs mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="content-col-form-livraison position-relative cpntrol">
                      <label className="label-checkbox-register-tabs d-flex align-items-center">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          {...register("mode_de_paiement")}
                          value="BANK_TRANSFER_SN_API_CASH_OUT"
                        />
                        Carte 
                      </label>
                      <div className="icon-mode-paiement-position">
                        <img src={chart} alt="carte" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"><div></div></div>
                </div>
              </div>
              <div className="col-form-register-tabs mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="content-col-form-livraison position-relative cpntrol">
                      <label className="label-checkbox-register-tabs d-flex align-items-center">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          {...register("mode_de_paiement")}
                          value="CASH"
                        />
                        Paiement à la livraison 
                      </label>
                      <div className="icon-mode-paiement-position">
                        <img src={livreur} alt="carte" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"><div></div></div>
                </div>
              </div>
          </div>
      </div>
    </div>
  );
};

export default ModePaiement;
