import React, { useState } from "react";
import Logo from "../../assets/appImages/logo.svg";
import { UseForgetPasswordForm } from "./requestForm/UseForgetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { onCloseModal } from "../../utils/closeModal";
import { UseResetPasswordForm } from "./requestForm/UseResetPasswordForm";
import ConnexionModal from "../shared/FrontHeader/modal/ConnexionModal";
import { MdMail } from "react-icons/md";

function ResetPassword() {
  const [openModal, setOpenModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onOpenModal = () => {
    setOpenModal(true)
  }

  const {
    register,
    errors,
    onSubmit,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseResetPasswordForm(onOpenModal);
  return (
    <div className="container-page-login-app">
      <div className="auth-row">
        <div className="auth-col auth-right-side-col">
          <div className="auth-right-side-container">
            <div className="auth-form-container text-center pb-4 w-100">
              <div className="content-logo-auth pb-2 pt-5">
                <img src={Logo} alt="logo" className="logo-auth-page" />
              </div>
              <div className="text-center pb-4">
                <h2 className="title-login-modal">
                  Réinitialiser votre mot de passe
                </h2>
              </div>
              <form className="mt-4" onSubmit={onSubmit}>
                <div className="row row-add-student">
                  <div className="col-md-12 auth-input-col mb-3">
                      <label 
                        htmlFor="email"
                        className="auth-form-label pb-2"
                        >
                          Ancien mot de passe
                      </label>
                      <div className="auth-form-group-rounded">
                        <span className="icon-input">
                          <MdMail />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Email"
                          aria-label="Email"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                  </div>
                  <div className="col-md-12 auth-input-col mb-3">
                      <label 
                        htmlFor="email"
                        className="auth-form-label pb-2"
                        >
                          Nouveau mot de passe
                      </label>
                      <div className="auth-form-group-rounded">
                        <span className="icon-input">
                          <MdMail />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Email"
                          aria-label="Email"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                  </div>
                  <div className="col-md-12 auth-input-col mb-3">
                      <label 
                        htmlFor="email"
                        className="auth-form-label pb-2"
                        >
                          Confirmer mot de passe
                      </label>
                      <div className="auth-form-group-rounded">
                        <span className="icon-input">
                          <MdMail />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Email"
                          aria-label="Email"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group my-2 password-validation-terms-row">
                      <div className="password-validation-terms-container">
                        <h4 className="password-validation-terms-title">
                          Votre mot de passe doit contenir :{" "}
                        </h4>
                        <ul className="password-validation-terms-ul p-l-14 text-start">
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinCharacter && "text-success")
                            }
                          >
                            Au minimum 8 caractères
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinUppercase && "text-success")
                            }
                          >
                            Au minimum 1 majuscule
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinLowercase && "text-success")
                            }
                          >
                            Au minimum 1 minuscule
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinNumber && "text-success")
                            }
                          >
                            Au minimum 1 chiffre
                          </li>
                          <li
                            className={
                              "password-rule-item " +
                              (!!haveMinSpecialCharacter && "text-success")
                            }
                          >
                            Au minimum 1 caractère spécial
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center pt-5">
                        <button
                          // to="/admin/dashboard"
                          className="btn auth-submit-btn trans-0-2"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Enregistrement...</span>
                            </>
                          ) : (
                            "Enregistrer"
                          )}
                        </button>
                  </div>
                </div>
              </form>
              <ConnexionModal
                modalConnexion={openModal}
                setModalConnexion={setOpenModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
